const chartColorList: { [key: string]: string } = {
  total_requirement_requested: '#E8E8E8',
  total_requirement_approved: '#E8E8E8',
  total_inscript: '#99E0C7',
  main_bar: '#ADABAB',
  new: '#99E0C7',
  not_read: '#EA829E',
  exit: '#D4D897',
};

const chartColorHoverList: { [key: string]: string } = {
  total_requirement_requested: '#adabab',
  total_requirement_approved: '#adabab',
  total_inscript: '#009ad3',
  new: '#009ad3',
  not_read: '#E62155',
  exit: '#9da423',
};

export function getBarColor(value: string): string {
  return chartColorList[value];
}

export function getBarHoverColor(value: string): string {
  return chartColorHoverList[value];
}
