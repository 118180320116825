import { Grid } from '@mui/material';
import { t } from 'i18next';
import { useLocation } from 'react-router-dom';
import { Breadcumb } from '../../components/Breadcumb';
import { Title } from '../../components/Title';
import { SearchInfo } from './SearchInfo';
import { Result } from './Result';
import styles from './styles.module.scss';

export function Extinguish() {
  const { pathname } = useLocation();
  return (
    <div className={styles.container}>
      <Grid
        container
        columns={{ xs: 2, sm: 6, md: 12 }}
        spacing={1}
      >
        <Grid item xs={2} sm={6} md={12}>
          <Title text={ t('extinguish.title') } />
          <Breadcumb pathnames={pathname.split('/').filter((x) => x)}/>
        </Grid>

        <Grid item xs={2} sm={6} md={12} className={styles.content}>
          <SearchInfo />
          <Result />
        </Grid>
      </Grid>
    </div>
  );
}
