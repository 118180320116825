import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { t } from 'i18next';
import { Trans } from 'react-i18next';

import { FaDollarSign, FaRegUser } from 'react-icons/fa';
import { PiNewspaperClipping } from 'react-icons/pi';
import { Header } from '../../../../components/Reports/Header';
import { Main } from '../../../../components/Reports/Main';
import { Footer } from '../../../../components/Reports/Footer';
import { Card } from '../../../../components/Reports/Card';
import { Alert } from '../../../../components/Reports/Alert';
import { CdasTable } from '../../components/CdasTable';
import { TableSimulation } from '../../components/TableSimulation';

import iconPing from '../../../../assets/img-ping.svg';
import imgFooter from '../../../../assets/img-bn-footer.svg';

import Service from '../../../../services/inscriptionService';

import { ContributorTotalDebtResponseType } from '../../../../@types/activeDebt/inscription';

import Format from '../../../../helpers/format';

import reportStyles from '../../reports.module.scss';
import styles from './styles.module.scss';

const date = new Date();
const currentTime = date.toLocaleString('en-CA', { hour12: false }).replace(',', '');
const lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0).toLocaleDateString('en-CA');

const InfoHeader = ({ contributorData }: { contributorData: ContributorTotalDebtResponseType | null }) => {
  return (
    <div className={styles.mes}>
      {contributorData && (
        <>
          <p>
            {t('reports.paymentRequestSimulate.infoMessage')} <b>{contributorData?.name},</b>
          </p><br/>
          <p>
            <Trans
              i18nKey='reports.paymentRequestSimulate.infoDescription'
              components={{ bold: <b /> }}
              values={{
                balance: Format.currency(contributorData.total_balance),
                current_date: Format.formatDate(currentTime),
              }}
            />
          </p>
        </>
      )}
    </div>
  );
};

const ContributorDetailsCard = ({ contributorData }: { contributorData: ContributorTotalDebtResponseType | null }) => {
  return (
    <Card
      iconTitle={<FaRegUser />}
      largeClass={reportStyles.col6}
      title={t('reports.paymentRequestSimulate.cards.contributor.title')}
    >
      <div className={styles.contentCard} data-cy={'contributor-basic-data'}>
        {contributorData && (
          <>
            <p>
              <b data-cy={'contributor-name'}>{t('reports.paymentRequestSimulate.cards.contributor.name')}: </b>
              <span className={reportStyles.up}> {contributorData?.name}</span>
            </p>
            <p>
              <b data-cy={'contributor-document'}>{t('reports.paymentRequestSimulate.cards.contributor.document')}: </b>
                {contributorData?.document}
            </p>
            <p>
              <b data-cy={'contributor-address'}>{t('reports.paymentRequestSimulate.cards.contributor.address')}: </b><br />
                {contributorData?.address}
            </p>
          </>
        )}
      </div>
    </Card>
  );
};

const ContributorDebtCard = ({ contributorData }: { contributorData: ContributorTotalDebtResponseType | null }) => {
  const debtIcon = () => {
    return (
      <div className={styles.iconDebt}>
        <FaDollarSign />
      </div>
    );
  };
  return (
    <Card
      iconTitle={debtIcon()}
      largeClass={reportStyles.col6}
      title={t('reports.paymentRequestSimulate.cards.cdas.title')}
    >
      <div className={styles.contentCard} data-cy={'cdas-balance'}>
        {contributorData && (
          <>
            <p>
              <b>
                <span data-cy={'select-cdas-balance'} className={reportStyles.up}>{t('reports.paymentRequestSimulate.cards.cdas.balance')} </span>
              </b>
              <i className={styles.font}>
                {t('reports.paymentRequestSimulate.cards.cdas.balanceDescription')}
              </i>
            </p>
            <p className={styles.txtRed}>{Format.currency(contributorData.total_balance)}</p>
            <p className={styles.txtObs}>
              <Trans
                i18nKey='reports.paymentRequestSimulate.cards.cdas.totals'
                components={{ bold: <b /> }}
                values={{
                  selectedInscriptionsCount: contributorData.inscriptions_selected_count,
                  inscriptionsCount: contributorData.inscriptions_count,
                  receiptTypeDescription: contributorData.receipt_type_description,
                }}
              />
            </p>
          </>
        )}
      </div>
    </Card>
  );
};

const knowMoreCard = () => {
  return (
    <Card
      iconTitle={<PiNewspaperClipping />}
      title={t('reports.paymentRequestSimulate.cards.knowMore.title')}
      largeClass={reportStyles.col12}
    >
      <div className={styles.contentCard} data-cy={'know-more-card'}>
        <p>
          <b data-cy={'loss-negotiation'}>{t('reports.paymentRequestSimulate.cards.knowMore.lossNegotiation')}:</b><br/>
          {t('reports.paymentRequestSimulate.cards.knowMore.lossNegotiationDescription')}
        </p><br/>
        <p>
          <b data-cy={'minimum-installments-value'}>{t('reports.paymentRequestSimulate.cards.knowMore.minimumInstallments')}:</b><br/>
          <ul>
            <li>{t('reports.paymentRequestSimulate.cards.knowMore.minimumInstallmentsCpf')}</li>
            <li>{t('reports.paymentRequestSimulate.cards.knowMore.minimumInstallmentsCnpj')}</li>
          </ul>
        </p><br/>
        <p><b data-cy={'loss-negotiation-conditions'}>{t('reports.paymentRequestSimulate.cards.knowMore.lossNegotiationConditions')}:</b><br/>
          {t('reports.paymentRequestSimulate.cards.knowMore.lossNegotiationConditionsDescription')}</p>
      </div>
    </Card>
  );
};

const detailsInscriptionsCard = (dataParams: any) => {
  return (
    <Card
      largeClass={reportStyles.col12}
      title={t('reports.paymentRequestSimulate.cards.list.title', { totalInscriptions: dataParams.inscriptions_ids.length })}
    >
      <div data-cy={'cdas-table'} className={styles.contentCard}>
        <CdasTable requestData={dataParams} />
      </div>
    </Card>
  );
};

const banner = () => {
  return (
    <div className={`${styles.content} ${reportStyles.col12}`}>
      <img src={iconPing} alt="" />
      <p>
        {t('reports.paymentRequestSimulate.avoidInterest')}<br />
        <strong>{t('reports.paymentRequestSimulate.payInCash')}</strong>
      </p>
    </div>
  );
};

const infoSimulation = () => {
  return (
    <div className={`${styles.headerTitleSimulation} ${reportStyles.col12}`}>
      <p><b className={reportStyles.up}>{t('reports.paymentRequestSimulate.label')}</b></p>
      <p>{t('reports.paymentRequestSimulate.observation')}</p>
    </div>
  );
};

const simulationCard = (dataParams: any) => {
  return (
    <div className={styles.contentSimulation}>
      <TableSimulation paymentRequestSimulationParams={dataParams} />
      <div className={styles.msgEnd}>
        <p>
          {
            t('reports.paymentRequestSimulate.description', {
              currentDate: Format.formatDateWithHours(currentTime),
              lastDayOfMonth: Format.formatDate(lastDayOfMonth),
            })
          }
        </p>
      </div>
    </div>
  );
};

const btnFooter = () => {
  return (
    <div className={styles.bnFooter}>
      <p>
        {t('reports.paymentRequestSimulate.negotiationDescription')}
      </p>
      <img src={imgFooter} alt="" />
    </div>
  );
};

export function PaymentRequestsSimulate() {
  const { contributorId, receiptTypeId, inscriptionsIds } = useParams();
  const [contributorData, setContributorData] = useState<ContributorTotalDebtResponseType | null>(null);

  const dataParams = {
    contributor_id: contributorId,
    receipt_type_id: receiptTypeId,
    inscriptions_ids: inscriptionsIds?.split(',') || [],
  };
  document.body.classList.remove('darkModeTheme');

  useEffect(() => {
    const getContributorData = async () => {
      try {
        const result = await Service.getContributorTotalDebt(dataParams);
        setContributorData(result);
      } catch (err) {
        setContributorData(null);
      }
    };

    getContributorData();
  }, [contributorId, receiptTypeId, inscriptionsIds]);

  return (
    <div>
      <Header
        title={t('reports.paymentRequestSimulate.title')}
        subtitle={contributorData?.receipt_type_description}
      />
      <Main>
        <InfoHeader contributorData={contributorData} />
        <section id='set-card'>
          <ContributorDetailsCard contributorData={contributorData} />
          <ContributorDebtCard contributorData={contributorData} />
          { knowMoreCard() }
        </section>
        <section id='banner-info' className={styles.banner}>
          { banner() }
        </section>
        <section id='pr-simulation' className={styles.simulation}>
          { infoSimulation() }
          { simulationCard(dataParams) }
        </section>
        <Alert
          show={false}
          description={t('reports.paymentRequestSimulate.alert')}
        />
        { detailsInscriptionsCard(dataParams) }
        { btnFooter() }
      </Main>
      <Footer />
    </div>
  );
}
