import {
  NegotiationPerMonthResponse,
  DebtAmountResponse, NegotiationProgress, Negotiations, Prescriptions,
} from '../@types/debtDashboard';
import Axios from '../config/api';

export default class DashboardService {
  public static async getReceiptTypes() {
    return Axios.get('api/dashboards_inscriptions/list_receipt_types')
      .then((res) => res.data);
  }

  public static async getDebtsAmount(query?: string) {
    return Axios.get<DebtAmountResponse>(`api/dashboards_inscriptions/debts_amount${query || ''}`)
      .then((res) => res.data);
  }

  public static async getPrescriptions(query?: string) {
    return Axios.get<Prescriptions>(`api/dashboards_inscriptions/prescriptions${query || ''}`)
      .then((res) => res.data);
  }

  public static async getNegotiations(query?: string) {
    return Axios.get<Negotiations>(`api/dashboards_inscriptions/negotiations${query || ''}`)
      .then((res) => res.data);
  }

  public static async getDebtsPerMonth(year: string, query?: string) {
    return Axios.get<NegotiationPerMonthResponse>(`api/dashboards_inscriptions/negotiations/per_month?year=${year}${query || ''}`)
      .then((res) => res.data);
  }

  public static async getNegotiationProgress(query?: string) {
    return Axios.get<NegotiationProgress[]>(`api/dashboards_inscriptions/inscriptions_progress${query || ''}`)
      .then((res) => res.data);
  }

  public static async getLastUpdatedInDashboard() {
    return Axios.get('api/dashboards/schedules')
      .then((res) => res.data);
  }
}
