import { useContext } from 'react';
import { Skeleton } from '@mui/material';
import { t } from 'i18next';
import { format, startOfYear } from 'date-fns';
import { DashboardContext } from '../../context/dashboardContext';
import { getBarColor, getBarHoverColor } from '../../helpers/colors';
import { Bar } from '../Charts/Bar';
import styles from './styles.module.scss';
import Format from '../../helpers/format';
import { HeaderDebt } from '../Charts/HeaderDebt';

export function DebtCollection() {
  const { debts, loading, totalDebts } = useContext(DashboardContext);
  const getPeriod = () => {
    return `${format(startOfYear(new Date()), 'dd/MM/yyyy')} ${t('dashboard.general.untilToday')}`;
  };
  const getPercentage = (partial: number, debtType: string) => {
    if (debtType === debts[2].title) {
      return (100 - (parseFloat(((100 * debts[0].total) / totalDebts).toFixed(2)) + parseFloat(((100 * debts[1].total) / totalDebts).toFixed(2))));
    }
    return (100 * partial) / totalDebts;
  };

  const getColor = (title: string) => {
    switch (title) {
      case 'total_requirement_requested':
        return 'var(--neutral2)';
      case 'total_requirement_approved':
        return 'var(--primary2)';
      case 'total_inscript':
        return 'var(--primary4)';
      default:
        return 'var(--neutral2)';
    }
  };

  const debtsPercentage = () => {
    return debts.map((item) => (
      {
        percentage: getPercentage(item.total, item.title),
        color: getColor(item.title),
      }
    ));
  };
  return (
    <div className={ styles.container }>
      <HeaderDebt
        title={t('dashboard.debtAmount.title')}
        info={t('dashboard.debtAmount.info')}
        period={getPeriod()}
        tooltip={t('dashboard.debtAmount.tooltip')}
        barColor={getBarColor('main_bar')}
        loading={loading || debts == null || debts.length === 0}
        totalValue={totalDebts || 0}
        debtsValues={debtsPercentage()}
      />
      {!loading || debts == null || debts.length === 0
        ? debts.map((item, index) => (
        <Bar
          key={index}
          color={getColor(item.title)}
          hoverColor={getBarHoverColor(item.title)}
          title={t(`dashboard.state.${item.title}`)}
          tooltip={Format.currency(item.total)}
          width={totalDebts ? (item.total / totalDebts) * 100 : 50}
          percentage_number={getPercentage(item.total, item.title).toFixed(2)
            .toString()
            .padStart(5, '0')
            .replace('.', ',')}
        />
        ))
        : <Skeleton variant={'rectangular'} className={ styles.row }/>}
    </div>
  );
}
