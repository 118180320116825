import { ReactNode } from 'react';
import styles from './styles.module.scss';

type SubtitleProps = {
  text: string;
  regularText?: boolean;
  rightItem?: ReactNode;
  primaryColor?: boolean;
};

export function Subtitle(props: SubtitleProps) {
  return (
    <div className={ styles.container }>
      <span
        className={`${props.regularText ? styles.regularText : ''} ${props.primaryColor ? styles.primaryColor : ''}`}
      >
        {props.text}
      </span>
      {props.rightItem && props.rightItem}
    </div>
  );
}
