import { useTranslation } from 'react-i18next';
import './styles.scss';
import { useEffect, useState, useRef } from 'react';
import { GroupMenu } from './GroupMenu';
import { Line } from './Line';
import {
  itemsModulesActiveDebt,
  itemsSupport,
  itemsSettings,
  digitalProcessesLogo,
} from '../../../helpers/menuItems';
import Screen from '../../../helpers/screen';
import { useOnClickOutside } from '../../CustomHooks';
import { BarButton } from './BarButton';

type SidebarProps = {
  onHover: (hover: boolean) => void;
  setOpenMobile: (hover: boolean) => void;
  openMobile: boolean;
  onClose: (close: boolean) => void;
};

export function Sidebar(props: SidebarProps) {
  const { t } = useTranslation();
  const ref = useRef(null);
  const [opened, setOpened] = useState(false);
  const [hover, setHover] = useState(false);
  const isMobile = Screen.isMobile();

  useEffect(() => {
    setHover(opened);
  }, [opened]);

  useEffect(() => {
    props.onHover(hover || opened);
  }, [hover]);

  useEffect(() => {
    setHover(props.openMobile);
    setOpened(props.openMobile);
  }, [props.openMobile]);

  const handleMouseEnter = () => {
    setHover(true);
  };

  const handleMouseLeave = () => {
    setHover(false);
  };

  const isOpen = () => hover || opened;

  const closeMenu = () => {
    setOpened(!opened);
    props.setOpenMobile(false);
  };

  useOnClickOutside(ref, () => closeMenu());
  return (
    <div
      data-cy={'sidebar'}
      id={'sidebar'}
      className={`sidebarContainer ${opened || hover ? 'opened' : ''} ${!props.openMobile ? 'displayNone' : ''} `}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      ref={isMobile && opened ? ref : null}
    >
      <BarButton
        opened={ opened }
        onClick={ () => closeMenu() }
      />
      <div className='content'>
        <div className='content-menu'>
          <Line hidden={ isOpen() }/>
          <GroupMenu
            title={ t('sidebar.modules.active_debt.title')}
            isOpen={ isOpen() }
            items={ itemsModulesActiveDebt }
            closeMenu={() => closeMenu()}
          />
          <Line hidden={ isOpen() }/>
          <GroupMenu
            title={ t('sidebar.modules.support.title')}
            isOpen={ isOpen() }
            items={ itemsSupport }
            closeMenu={() => closeMenu()}
          />
          <Line hidden={ isOpen() }/>
          <GroupMenu
            title={ t('sidebar.modules.systems')}
            isOpen={ isOpen() }
            items={ digitalProcessesLogo }
            closeMenu={() => closeMenu()}
          />
        </div>
        <div className='content-footer'>
          { isOpen() ? <div className='line-footer' /> : <Line/> }
          <GroupMenu
            title={ t('sidebar.modules.support.settings_sistem')}
            isOpen={ isOpen() }
            isFooter
            items={ itemsSettings }
            closeMenu={() => closeMenu()}
          />
        </div>
      </div>
    </div>
  );
}
