import { t } from 'i18next';
import {
  FaFileAlt, FaTrashAlt,
} from 'react-icons/fa';
import styles from './styles.module.scss';
import { AttachDocument, OptionType } from '../../../../../@types/config';
import { Table } from '../../../../../components/Table';

type ListFilesProps = {
  documents: any[];
  removeFile: (files: AttachDocument) => void;
  types: OptionType[];
};

export function ListFiles(props: ListFilesProps) {
  return (
    <div className={ styles.container }>
      <Table>
        <thead>
          <tr>
            <th>{t('requirements.new.attach.list.name')}</th>
            <th>{t('requirements.new.attach.list.type')}</th>
            <th>{t('requirements.new.attach.list.delete')}</th>
          </tr>
        </thead>
        {
        props.documents.map((document) => (
          <tbody>
            <td>
              <FaFileAlt />
              <a target='_blank'>
                {document.file.name}
              </a>
            </td>
            <td>
              {props.types.find((type) => type.value === document.type)?.label || ''}
            </td>
            <td>
              <a
                className={styles.buttonDelete}
                onClick={() => props.removeFile(document)}
              >
                <FaTrashAlt />
              </a>
            </td>
          </tbody>
        ))}
      </Table>
    </div>
  );
}
