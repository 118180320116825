export default {
  translations: {
    extinguish: {
      title: 'Extinguir/suspender crédito',
      contributor: 'Contribuinte',
      contributorLabel: 'CPF/CNPJ ou nome',
      admInscription: 'Inscrição Administrativa',
      admInscriptionLabel: '000000',
      process: 'Processo Judicial',
      processLabel: '0000000000000',
      search: 'Consultar',
      result: 'Lista de inscrições',
      select: 'Selecionar todas',
      remove: 'Remover seleção',
      item: 'Inscrição Administrativa: {{inscription}} - CDA: {{cda_number}} - Exercício(s): {{year}}',
      details: {
        responsible: '<bold>Responsável:</bold> {{responsible}}',
        admInscription: '<bold>Inscrição Administrativa:</bold> {{admInscription}}',
        originKey: '<bold>Chave da origem:</bold> {{originKey}}',
        cdaNumber: '<bold>CDA:</bold> {{cdaNumber}}',
        judicialProcess: '<bold>Processo judicial:</bold> {{judicialProcess}}',
        state: '<bold>Fase:</bold> {{state}}',
        year: '<bold>Exercício:</bold> {{year}}',
      },
      action: 'Selecione a ação desejada:',
      reason: 'Justificativa:',
      reasonPlaceholder: 'Digite seu texto aqui',
    },
  },
};
