import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';
import { t } from 'i18next';
import { Skeleton } from '@mui/material';
import { ReceiptTypeSummary } from '../../../../@types/activeDebt/reports';
import { Card } from '../../../../components/Reports/Card';
import { Header } from '../../../../components/Reports/Header';
import { Main } from '../../../../components/Reports/Main';
import { Table } from '../../../../components/Table';
import { DebtBoxes } from '../DebtBoxes';
import styles from './styles.module.scss';
import DebtBoxService from '../../../../services/debtBoxService';
import Format from '../../../../helpers/format';
import reportStyles from '../../reports.module.scss';

type ReceiptTypeSumaryProps = {
  contributorId: string | undefined;
  receipTypeDescription: ReceiptTypeSummary;
  activeInscriptions: number[] | null;
  negotiationInscriptions: number[] | null;
  extinctiInscriptions: number[] | null;
  details: boolean;
};

export function ReceiptTypeSumary(props: ReceiptTypeSumaryProps) {
  const extinctBallance = 0.00;
  const getOpenDebtsSummary = async () => {
    const data = {
      contributor_id: props.contributorId,
      receipt_type_id: props.receipTypeDescription.id,
      inscriptions_ids: props.activeInscriptions,
    };
    const result = await DebtBoxService.getResumeInscriptions(data);
    return result;
  };

  const getNegotiationDebtsSummary = async () => {
    const data = {
      contributor_id: props.contributorId,
      receipt_type_id: props.receipTypeDescription.id,
      inscriptions_ids: props.negotiationInscriptions,
    };
    const result = await DebtBoxService.getResumeInscriptions(data);
    return result;
  };

  const getExtinctDebtsSummary = async () => {
    const data = {
      contributor_id: props.contributorId,
      receipt_type_id: props.receipTypeDescription.id,
      inscriptions_ids: props.extinctiInscriptions,
    };
    const result = await DebtBoxService.getResumeInscriptions(data);
    return result;
  };

  const {
    data: openDebtsData, refetch: openDebts, isFetching: fetchingOpen,
  } = useQuery({
    queryKey: [props.receipTypeDescription?.active_inscriptions_ids],
    queryFn: getOpenDebtsSummary,
    enabled: false,
  });

  const {
    data: negotiationDebtsData, refetch: negotiationDebts, isFetching: fetchingNegotiations,
  } = useQuery({
    queryKey: [props.receipTypeDescription?.balance_active_negotiation],
    queryFn: getNegotiationDebtsSummary,
    enabled: false,
  });

  const {
    data: extinctDebtsData, refetch: extinctDebts, isFetching: fetchingExtinct,
  } = useQuery({
    queryKey: [props.receipTypeDescription?.extinct_inscriptions_ids],
    queryFn: getExtinctDebtsSummary,
    enabled: false,
  });

  useEffect(() => {
    if (props.receipTypeDescription) {
      const receiptProp = props.receipTypeDescription;
      if (receiptProp.active_inscriptions_ids) {
        openDebts();
      }
      if (receiptProp.active_negotiation_inscriptions_ids) {
        negotiationDebts();
      }
      if (receiptProp.extinct_inscriptions_ids) {
        extinctDebts();
      }
    }
  }, [props.activeInscriptions, props.negotiationInscriptions, props.extinctiInscriptions]);

  const renderTableinfos = (admInscription: string, total: string, iptuAddress?: string, total_balance?: number) => {
    return (
      <div className={styles.tableHeadInfo}>
        <div className={styles.leftInfos}>
          <span>
            <strong>{t('reports.fullStatement.receiptTypeInfos.table.header.administrativeInscriptions')}</strong>
            <span>{admInscription}</span>
          </span>
          <span>
            <strong>{t('reports.fullStatement.receiptTypeInfos.table.header.cdas')}</strong>
            <span>{total}</span>
          </span>
          { iptuAddress && (
            <span>
              <strong>{t('reports.fullStatement.receiptTypeInfos.table.header.address')}</strong>
              <span>{iptuAddress}</span>
            </span>
          )
          }
        </div>
        <div className={styles.rigthInfo}>
          {total_balance
            && (
              <>
                <strong>Total: </strong>
                <span>{Format.currency(total_balance)}</span>
              </>
            )
          }
        </div>
      </div>
    );
  };

  const renderTableHeader = () => {
    if (props.details) {
      return (
        <tr>
          <th data-cy="custom-report-cda">{t('reports.fullStatement.receiptTypeInfos.table.cda')}</th>
          <th data-cy="custom-report-phase">{t('reports.fullStatement.receiptTypeInfos.table.phase')}</th>
          <th data-cy="custom-report-exercice">{t('reports.fullStatement.receiptTypeInfos.table.exercicie')}</th>
          <th data-cy="custom-report-process">{t('reports.fullStatement.receiptTypeInfos.table.process')}</th>
          <th data-cy="custom-report-current-balance">{t('reports.fullStatement.receiptTypeInfos.table.currentBalance')}</th>
          <th data-cy="custom-report-punitive-fine">{t('reports.fullStatement.receiptTypeInfos.table.currentPunitiveFine')}</th>
          <th data-cy="custom-report-monetary-correction">{t('reports.fullStatement.receiptTypeInfos.table.monetaryCorrection')}</th>
          <th data-cy="custom-report-fees">{t('reports.fullStatement.receiptTypeInfos.table.fees')}</th>
          <th data-cy="custom-report-moratorium-fines">{t('reports.fullStatement.receiptTypeInfos.table.moratoriumFines')}</th>
          <th data-cy="custom-report-charges">{t('reports.fullStatement.receiptTypeInfos.table.charges')}</th>
          <th data-cy="custom-report-debt-balance">{t('reports.fullStatement.receiptTypeInfos.table.debitBalance')}</th>
        </tr>
      );
    }
    return (
      <tr>
        <th data-cy="custom-report-cda">{t('reports.fullStatement.receiptTypeInfos.table.cda')}</th>
        <th data-cy="custom-report-phase">{t('reports.fullStatement.receiptTypeInfos.table.phase')}</th>
        <th data-cy="custom-report-exercice">{t('reports.fullStatement.receiptTypeInfos.table.exercicie')}</th>
        <th data-cy="custom-report-process">{t('reports.fullStatement.receiptTypeInfos.table.process')}</th>
        <th data-cy="custom-report-current-balance">{t('reports.fullStatement.receiptTypeInfos.table.currentBalance')}</th>
        <th data-cy="custom-report-punitive-fine">{t('reports.fullStatement.receiptTypeInfos.table.currentPunitiveFine')}</th>
        <th data-cy="custom-report-acessories">{t('reports.fullStatement.receiptTypeInfos.table.accessories')}</th>
        <th data-cy="custom-report-debt-balance">{t('reports.fullStatement.receiptTypeInfos.table.debitBalance')}</th>
      </tr>
    );
  };

  const renderDetails = (debt: any) => {
    if (props.details) {
      return (
        <>
          <td className={styles.leftBorder}>{Format.currency(debt.monetary_correction)}</td>
          <td className={styles.leftBorder}>{Format.currency(debt.interest_value)}</td>
          <td className={styles.leftBorder}>{Format.currency(debt.moratorium_fines)}</td>
          <td className={styles.leftBorder}>{Format.currency(debt.fee_value)}</td>
        </>
      );
    }
    return (
      <td className={styles.leftBorder}>
        {Format.currency(debt.monetary_correction + debt.moratorium_fines + debt.interest_value + debt.fee_value)}
      </td>
    );
  };

  return (
    <div className={styles.receipContainer}>
      <Header
        type='custom'
        title={`${props.receipTypeDescription.description}`}
        simplifyed={true}
      />
      <div className={styles.mainCard}>
        <Main>
          <Card
            title={t('reports.fullStatement.receiptTypeInfos.mainCards.title', { receiptType: props.receipTypeDescription.description })}
            largeClass={reportStyles.col12}
            custom
          >
            <DebtBoxes
              totalCDAs={props.receipTypeDescription.total}
              totalBalance={props.receipTypeDescription.total_balance}
              openInscriptions={ { balance: props.receipTypeDescription.balance_active_inscriptions, total: props.receipTypeDescription.total_active_inscriptions } }
              negotiationInscriptions={{ balance: props.receipTypeDescription.balance_active_negotiation, total: props.receipTypeDescription.total_active_negotiation }}
            />
          </Card>
          <div className={styles.openDebtsTable}>
            {fetchingOpen ? <Skeleton height={150}/>
              : openDebtsData
              && <Card
                  title={t('reports.fullStatement.receiptTypeInfos.cards.openTitle', { receiptType: props.receipTypeDescription.description, cdas: props.receipTypeDescription.active_inscriptions_ids.length })}
                  largeClass={reportStyles.col12}
                  subtitle={t('reports.fullStatement.receiptTypeInfos.cards.subtitleOpen')}
                  custom
                >
              {openDebtsData && openDebtsData.data.map((od: any) => (
                <>
                  {renderTableinfos(od.administrative_inscription, od.total, od.iptu_address, od.total_balance)}
                  <Table>
                    <thead>
                      {renderTableHeader()}
                    </thead>
                    <tbody>
                      {od.inscriptions.map((db: any) => (
                        db.debts.map((dbts: any) => (
                          <tr className={`${props.details ? styles.cdasTableContentDetails : styles.cdasTableContent}`}>
                            <td>{db.cda_number}</td>
                            <td className={styles.leftBorder}>{db.state_i18n}</td>
                            <td className={styles.leftBorder}>{format(new Date(dbts.due_date), 'yyyy')}</td>
                            <td className={styles.leftBorder}>{db.judicial_process_number}</td>
                            <td className={styles.leftBorder}>{Format.currency(dbts.main_balance)}</td>
                            <td className={styles.leftBorder}>{Format.currency(dbts.fines_balance)}</td>
                            {renderDetails(dbts)}
                            <td className={styles.leftBorder}><strong>{Format.currency(dbts.balance)}</strong></td>
                          </tr>
                        ))
                      ))}
                    </tbody>
                  </Table>
                </>
              ))}
            </Card>}
            <div className={styles.debtList}>
              {fetchingNegotiations ? <Skeleton height={150}/>
                : negotiationDebtsData
                && <Card
                    title={t('reports.fullStatement.receiptTypeInfos.cards.negotiationTitle', { receiptType: props.receipTypeDescription.description, cdas: props.receipTypeDescription?.active_negotiation_inscriptions_ids?.length })}
                    largeClass={reportStyles.col12}
                    subtitle={t('reports.fullStatement.receiptTypeInfos.cards.subtitleNegotiation')}
                    custom
                  >
                {negotiationDebtsData && negotiationDebtsData.data.map((nd: any) => (
                  <>
                    {renderTableinfos(nd.administrative_inscription, nd.total, nd.iptu_address, nd.total_balance)}
                      <Table>
                        <thead>
                          {renderTableHeader()}
                        </thead>
                        <tbody>
                          {nd.inscriptions.map((db: any) => (
                            db.debts.map((dbts: any) => (
                              <tr className={`${props.details ? styles.cdasTableContentDetails : styles.cdasTableContent}`}>
                                <td>{db.cda_number}</td>
                                <td className={styles.leftBorder}>{db.state_i18n}</td>
                                <td className={styles.leftBorder}>{format(new Date(dbts.due_date), 'yyyy')}</td>
                                <td className={styles.leftBorder}>{db.judicial_process_number}</td>
                                <td className={styles.leftBorder}>{Format.currency(dbts.main_balance)}</td>
                                <td className={styles.leftBorder}>{Format.currency(dbts.fines_balance)}</td>
                                {renderDetails(dbts)}
                                <td className={styles.leftBorder}><strong>{Format.currency(dbts.balance)}</strong></td>
                              </tr>
                            ))
                          ))}
                        </tbody>
                      </Table>
                  </>
                ))}
              </Card>}
            </div>
            <div className={styles.debtList}>
              {fetchingExtinct ? <Skeleton height={150}/>
                : extinctDebtsData
                && <Card
                    title={t('reports.fullStatement.receiptTypeInfos.cards.extinctTitle', { receiptType: props.receipTypeDescription.description, cdas: props.receipTypeDescription.extinct_inscriptions_ids.length })}
                    largeClass={reportStyles.col12} grayStyle={true}
                    subtitle={t('reports.fullStatement.receiptTypeInfos.cards.subtitleExtinct')}
                    custom
                  >
                {extinctDebtsData && extinctDebtsData.data.map((ed: any) => (
                  <>
                    {renderTableinfos(ed.administrative_inscription, ed.total, ed.iptu_address)}
                      <Table>
                        <thead>
                          {renderTableHeader()}
                        </thead>
                        <tbody>
                          {ed.inscriptions.map((db: any) => (
                            db.debts.map((dbts: any) => (
                              <tr className={`${props.details ? styles.cdasTableContentDetails : styles.cdasTableContent}`}>
                                <td>{db.cda_number}</td>
                                <td className={styles.leftBorder}>{db.state_i18n}</td>
                                <td className={styles.leftBorder}>{format(new Date(dbts.due_date), 'yyyy')}</td>
                                <td className={styles.leftBorder}>{db.judicial_process_number}</td>
                                <td className={styles.leftBorder}>{Format.currency(extinctBallance)}</td>
                                <td className={styles.leftBorder}>{Format.currency(extinctBallance)}</td>
                                <td className={styles.leftBorder}>{Format.currency(extinctBallance)}</td>
                                <td className={styles.leftBorder}>{Format.currency(extinctBallance)}</td>
                                <td className={styles.leftBorder}>{Format.currency(extinctBallance)}</td>
                                <td className={styles.leftBorder}>{Format.currency(extinctBallance)}</td>
                                <td className={styles.leftBorder}><strong>{Format.currency(extinctBallance)}</strong></td>
                              </tr>
                            ))
                          ))}
                        </tbody>
                      </Table>
                  </>
                ))}
              </Card>}
            </div>
          </div>
        </Main>
      </div>
    </div>
  );
}
