import React, { useContext } from 'react';
import { Routes, Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { Layout } from '../components/Layout';
import { Home } from '../pages/Home';
import { DebtBox } from '../pages/DebtBox';
import { globalTheme } from '../styles/globalTheme';

import '../config/i18n';
import { DebtBoxSearch } from '../pages/DebtBoxSearch';
import { PaymentRequest } from '../pages/PaymentRequest';
import { InscriptionDetails } from '../pages/InscriptionDetails';
import { ContributorBox } from '../pages/ContributorBox';
import { ThemeContext } from '../context/themeContext';
import { PaymentRequestsSimulate } from '../pages/Reports/PaymentRequests/Simulate';
import { FullStatement } from '../pages/Reports/FullStatement';
import { StatementResponsibility } from '../pages/Sidebar/StatementResponsibility';
import { Custom } from '../pages/Reports/Custom';
import BlockedAccess from '../components/BlockedAccess';
import { NewRequirement } from '../pages/Requirements/NewRequirement';
import { Extinguish } from '../pages/Extinguish';

const OtherRoutes: React.FC = () => {
  const { themeMode } = useContext(ThemeContext);

  if (themeMode === 'dark') document.body.classList.add('darkModeTheme');
  return (
    <>
      <ThemeProvider theme={globalTheme}>
        <Routes>
          <Route>
            <Route path="/reports/payment_requests/simulate/:contributorId/:receiptTypeId/:inscriptionsIds" element={<PaymentRequestsSimulate />} />
            <Route path="/reports/full_statement/:id/:detailed?" element={<FullStatement />} />
            <Route path="/reports/custom/:id" element={<Custom />} />
          </Route>
          <Route element={<Layout />}>
            <Route path="/" element={<Home />} />
            <Route path="/debt_box" element={<DebtBox />} />
            <Route path="/debt_box_search" element={<DebtBoxSearch />} />
            <Route path="/payment_request/:id" element={<PaymentRequest />} />
            <Route path="/inscription/:id" element={<InscriptionDetails />} />
            <Route path="/contributor_box" element={<ContributorBox />} />
            <Route path="/blocked" element={<BlockedAccess />} />
            <Route path="/new_requirement" element={<NewRequirement />} />
            <Route path="/active_debt/inscriptions/statement_responsibility" element={<StatementResponsibility />} />
            <Route path="/extinguish" element={<Extinguish />} />
          </Route>
        </Routes>
      </ThemeProvider>
    </>
  );
};

export default OtherRoutes;
