import { useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { t } from 'i18next';
import { Grid } from '@mui/material';
import { Trans } from 'react-i18next';
import { DebtsTable } from '../../../components/DebtsTable';
import { CheckBox } from '../../../components/CheckBox';
import { InscriptionExtinguish } from '../Result';
import styles from './styles.module.scss';

type ItemProps = {
  inscription: InscriptionExtinguish;
  selectedItems: InscriptionExtinguish[]
  onSelect: (item: InscriptionExtinguish) => void;
};

export function Item(props: ItemProps) {
  const [open, setOpen] = useState(false);
  return (
    <div className={`${styles.container} ${props.selectedItems.includes(props.inscription) && styles.selected}`}>
      <div className={styles.content}>
        <CheckBox
          value={props.selectedItems.includes(props.inscription)}
          uncheckedColor='var(--neutral6)'
          color='var(--primary4)'
          onClick={() => props.onSelect(props.inscription)}
          dataCy='checkbox-inscription'
        />
        <a
          className={styles.button}
          onClick={() => setOpen(!open)}
        >
          <div className={styles.firstLine}>
            <div className={styles.number}>
              <span>{t('extinguish.item', {
                inscription: props.inscription.admInscription,
                cda_number: props.inscription.cdaNumber,
                year: props.inscription.year,
              })}</span>
            </div>
          </div>
        </a>
        <a
          className={styles.arrow}
          onClick={() => setOpen(!open)}
        >
          {open ? <FaChevronUp /> : <FaChevronDown />}
        </a>
      </div>
      {open && (
        <Grid
          container
          columns={{ xs: 2, sm: 6, md: 12 }}
          spacing={2}
          className={styles.details}
        >
          <Grid item xs={2} sm={3} md={4}>
            <span className={styles.title}>
              <Trans
                i18nKey='extinguish.details.responsible'
                components={{ bold: <strong /> }}
                values={{ responsible: props.inscription.details.responsible }}
              />
            </span>
          </Grid>
          <Grid item xs={2} sm={3} md={4}>
            <span className={styles.title}>
              <Trans
                i18nKey='extinguish.details.admInscription'
                components={{ bold: <strong /> }}
                values={{ admInscription: props.inscription.details.admInscription }}
              />
            </span>
          </Grid>
          <Grid item xs={2} sm={3} md={4}>
            <span className={styles.title}>
              <Trans
                i18nKey='extinguish.details.originKey'
                components={{ bold: <strong /> }}
                values={{ originKey: props.inscription.details.originKey }}
              />
            </span>
          </Grid>
          <Grid item xs={2} sm={3} md={4}>
            <span className={styles.title}>
              <Trans
                i18nKey='extinguish.details.cdaNumber'
                components={{ bold: <strong /> }}
                values={{ cdaNumber: props.inscription.details.cdaNumber }}
              />
            </span>
          </Grid>
          <Grid item xs={2} sm={3} md={4}>
            <span className={styles.title}>
              <Trans
                i18nKey='extinguish.details.judicialProcess'
                components={{ bold: <strong /> }}
                values={{ judicialProcess: props.inscription.details.judicialProcess }}
              />
            </span>
          </Grid>
          <Grid item xs={2} sm={3} md={4}>
            <span className={styles.title}>
              <Trans
                i18nKey='extinguish.details.state'
                components={{ bold: <strong /> }}
                values={{ state: props.inscription.details.state }}
              />
            </span>
          </Grid>
          <Grid item xs={2} sm={3} md={4}>
            <span className={styles.title}>
              <Trans
                i18nKey='extinguish.details.year'
                components={{ bold: <strong /> }}
                values={{ year: props.inscription.details.year }}
              />
            </span>
          </Grid>
          <Grid item xs={2} sm={6} md={12}>
            <DebtsTable inscription={props.inscription.id} />
          </Grid>
        </Grid>
      )}
    </div>
  );
}
