export default {
  translations: {
    requirements: {
      new: {
        title: 'Cadastrar requerimento',
        subtitle: 'Cadastro para inscrição em Dívida ativa',
        lawText: 'Requeremos a inscrição do crédito abaixo descrito, em Dívida Ativa do Município, declarando que o <strong>devedor foi devidamente notificado</strong>, conforme prazos legais previstos no Decreto n° 13.601/2015, art. 4°.',
        tabs: {
          debtInfo: 'Informações da dívida',
          responsibles: 'Corresponsáveis',
          propertyInfo: 'Informações do imóvel',
        },
        form: {
          title: 'Dados gerais',
          origin: 'Órgão de origem',
          originPlaceholder: 'Selecione o órgão de origem',
          receiptType: 'Tipo de receita',
          receiptTypePlaceholder: 'Selecione o tipo de receita',
          inscription: 'Inscrição administrativa',
          inscriptionPlaceholder: 'Selecione a inscrição administrativa',
          creditSource: 'Natureza do crédito',
          creditSourcePlaceholder: 'Selecione a natureza do crédito',
          documentBase: 'Documento base para inscrição',
          documentBasePlaceholder: 'Selecione o documento base para inscrição',
          documentNumber: 'Número do documento',
          documentNumberPlaceholder: 'Digite o número do documento',
          documentDate: 'Data documento',
          observation: 'Obervação',
          observationPlaceholder: 'Digite a obervação',
          tax: 'Trib',
          nontax: 'Não trib',
        },
        debtor: {
          title: 'Identificação do devedor',
          selectDebtor: 'Escolher contribuinte existente',
          selectDebtorPlaceholder: 'Selecione o contribuinte existente',
          newDebtor: 'Cadastrar novo contribuinte',
        },
        responsibles: {
          title: 'Identificação do corresponsável',
          label: 'Escolher corresponsável existente',
          placeholder: 'Digite o nome do corresponsável',
          add: 'Cadastrar novo corresponsável',
          submit: 'Cadastrar requerimento',
        },
        property: {
          title: 'Endereço',
        },
        attach: {
          title: 'Anexos',
          list: {
            name: 'Arquivo',
            type: 'Tipo de documento',
            delete: 'Excluir',
          },
        },
      },
    },
  },
};
