import { Trans } from 'react-i18next';
import { t } from 'i18next';
import { Grid } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { Subtitle } from '../../../../components/Subtitle';
import { InputText } from '../../../../components/InputText';
import AddressService from '../../../../services/addressService';
import styles from './styles.module.scss';

export function PropertyInfo() {
  const {
    control, handleSubmit, setValue, setError,
  } = useForm();

  const handleCepChange = async (event: any) => {
    const cepValue = event.target.value.replace(/\D/g, '');
    setValue('cep', cepValue);
    if (cepValue.length === 8) {
      try {
        const {
          logradouro, complemento, bairro, localidade, uf,
        } = await AddressService.getAddressFromCep(cepValue);
        setValue('street', logradouro);
        setValue('address_complement', complemento);
        setValue('neighborhood', bairro);
        setValue('city', localidade);
        setValue('state', uf);
      } catch (error) {
        setError('cep', { type: 'custom', message: t('general.cepError') });
      }
    }
  };

  const submit = async (form: any) => {};

  return (
    <div className={styles.container}>
      <span className={styles.lawText}>
        <Trans
          i18nKey='requirements.new.lawText'
          components={{ bold: <strong /> }}
        />
      </span>
      <div className={styles.content}>
        <Subtitle text={t('requirements.new.property.title')} primaryColor />
        <form onSubmit={handleSubmit(submit)}>
          <Grid
            container
            columns={{ xs: 2, sm: 6, md: 12 }}
            spacing={2}
            className={styles.form}
          >
            <Grid
              item
              xs={2}
              sm={3}
              md={4}
            >
              <Controller
                control={control}
                name="cep"
                render={({ field }) => (
                  <InputText
                    {...field}
                    dataCy={'cypress-cep-input-text'}
                    label={t('contributorBox.form.address.cep')}
                    placeholder={t('contributorBox.form.address.cepPlaceholder')}
                    mask={'99999-999'}
                    onChange={handleCepChange}
                    labelBold
                    required
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={2}
              sm={3}
              md={8}
            >
              <Controller
                control={control}
                name="street"
                render={({ field }) => (
                  <InputText
                    {...field}
                    dataCy={'cypress-street-input-text'}
                    label={t('contributorBox.form.address.addressField')}
                    placeholder={t('contributorBox.form.address.addressPlaceholder')}
                    labelBold
                    required
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={2}
              sm={3}
              md={4}
            >
              <Controller
                control={control}
                name="street_number"
                render={({ field }) => (
                  <InputText
                    {...field}
                    dataCy={'cypress-street-number-input-text'}
                    label={t('contributorBox.form.address.number')}
                    placeholder={t('contributorBox.form.address.numberPlaceholder')}
                    labelBold
                    required
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={2}
              sm={3}
              md={4}
            >
              <Controller
                control={control}
                name="neighborhood"
                render={({ field }) => (
                  <InputText
                    {...field}
                    dataCy={'cypress-neighborhood-input-text'}
                    label={t('contributorBox.form.address.neighborhood')}
                    placeholder={t('contributorBox.form.address.neighborhoodPlaceholder')}
                    labelBold
                    required
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={2}
              sm={3}
              md={4}
            >
              <Controller
                control={control}
                name="address_complement"
                render={({ field }) => (
                  <InputText
                    {...field}
                    dataCy={'cypress-street-complement-input-text'}
                    label={t('contributorBox.form.address.complement')}
                    placeholder={t('contributorBox.form.address.complementPlaceholder')}
                    labelBold
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={2}
              sm={3}
              md={4}
            >
              <Controller
                control={control}
                name="city"
                render={({ field }) => (
                  <InputText
                    {...field}
                    dataCy={'cypress-city-input-text'}
                    label={t('contributorBox.form.address.city')}
                    placeholder={t('contributorBox.form.address.city')}
                    labelBold
                    required
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={2}
              sm={3}
              md={4}
            >
              <Controller
                control={control}
                name="state"
                render={({ field }) => (
                  <InputText
                    {...field}
                    dataCy={'cypress-state-input-text'}
                    label={t('contributorBox.form.address.state')}
                    placeholder={t('contributorBox.form.address.state')}
                    labelBold
                    required
                  />
                )}
              />
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  );
}
