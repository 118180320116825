import { getYear } from 'date-fns';
import {
  createContext,
  FC,
  useEffect,
  useState,
} from 'react';
import { DashboardType } from '../@types/dashboard';
import {
  DebtAmount, NegotiationPerMonthResponse, NegotiationProgress, Negotiations, Prescriptions,
} from '../@types/debtDashboard';
import DashboardService from '../services/dashboardService';
import {
  debtAmountData, negotiationProgressData, negotiationsData, negotiationsPerMonthData,
} from './utils/debtData';

const contextDefaultValues: DashboardType = {
  totalDebts: 0,
  debts: [],
  receiptTypes: [],
  prescriptionsData: {
    total_next_prescriptions: 0,
    months: [],
  },
  negotiations: {
    total_paid: 0,
    total_lost: 0,
    total_start: 0,
    total_active: 0,
    total_overdue: 0,
  },
  negotiationsPerMonth: {
    year: 2023,
    total_paid: 1234,
    months: [
      {
        month: 1,
        total_paid: 1234,
      },
    ],
  },
  negotiationProgress: [],
  loading: false,
  unauthorizedDebt: false,
  query: '',
  prevYear: '',
  setPrevYear: () => {},
  selectedYear: '',
  setSelectedYear: () => {},
  addReceiptType: () => {},
};

export const DashboardContext = createContext<DashboardType>(
  contextDefaultValues,
);

const DashboardProvider: FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const defaultPrevYear: string = `${new Date().getFullYear() - 1}`;
  const [loading, setLoading] = useState<boolean>();
  const [unauthorizedDebt, setUnauthorizedDebt] = useState(false);
  const [query, setQuery] = useState('');
  const [prevYear, setPrevYear] = useState(defaultPrevYear);
  const [selectedYear, setSelectedYear] = useState(`${new Date().getFullYear()}`);
  const [debts, setDebts] = useState<DebtAmount[]>([]);
  const [prescriptionsData, setPrescriptionsData] = useState<Prescriptions>();
  const [negotiations, setNegotiations] = useState<Negotiations>();
  const [negotiationsPerMonth, setNegotiationsPerMonth] = useState<NegotiationPerMonthResponse>();
  const [negotiationProgress, setNegotiationProgress] = useState<NegotiationProgress[]>([]);
  const [totalDebts, setTotalDebts] = useState(0);
  const [receiptTypes, setReceiptTypes] = useState<number[]>([]);

  const loadDebtCollection = async () => {
    setDebts([]);
    try {
      const debtAmounts = await DashboardService.getDebtsAmount(query);
      setTotalDebts(debtAmounts.total_debts);
      setDebts(debtAmounts.debts);
      setUnauthorizedDebt(false);
    } catch (error) {
      setUnauthorizedDebt(true);
      setTotalDebts(debtAmountData.total_debts);
      setDebts(debtAmountData.debts);
    }
  };

  const loadPrescriptions = async () => {
    try {
      const data = await DashboardService.getPrescriptions(query);
      setPrescriptionsData(data);
    } catch (error) {
      setPrescriptionsData(undefined);
    }
  };

  const loadNegotiationYear = async () => {
    let queryPerMonth = '';
    receiptTypes.map((item) => {
      const prefix = 'receipt_type_id_in';
      queryPerMonth = queryPerMonth.concat(`&q[${prefix}][]=${item}`);
    });
    try {
      const dataPerMonth = await DashboardService.getDebtsPerMonth(selectedYear, queryPerMonth);
      setNegotiationsPerMonth(dataPerMonth);
    } catch (error) {
      setNegotiationsPerMonth(negotiationsPerMonthData);
    }
  };

  const loadDebtNegotiations = async () => {
    setNegotiations(undefined);
    try {
      loadNegotiationYear();
      const data = await DashboardService.getNegotiations(query);
      setNegotiations(data);
    } catch (error) {
      setNegotiations(negotiationsData);
    }
  };

  const loadNegotiationProgress = async () => {
    setNegotiationProgress([]);
    const currentYear = `${getYear(new Date())}`;
    const queryYear = `current_year=${currentYear}&year_ago=${prevYear || defaultPrevYear}`;
    let newQuery = '';
    if (query === '') {
      newQuery = `?${queryYear}`;
    } else {
      newQuery = query.concat(`&${queryYear}`);
    }
    try {
      const data = await DashboardService.getNegotiationProgress(newQuery);
      setNegotiationProgress(data);
    } catch (error) {
      setNegotiationProgress(negotiationProgressData);
    }
  };

  const updateQuery = () => {
    let newQuery = '';
    receiptTypes.map((item) => {
      const prefix = 'receipt_type_id_in';
      if (receiptTypes.indexOf(item) === 0) {
        newQuery = newQuery.concat(`?q[${prefix}][]=${item}`);
      } else {
        newQuery = newQuery.concat(`&q[${prefix}][]=${item}`);
      }
    });
    setQuery(newQuery);
  };

  const loadData = async () => {
    setLoading(true);
    try {
      loadDebtCollection();
      loadPrescriptions();
      loadDebtNegotiations();
      loadNegotiationProgress();
    } catch (error) {
      setTotalDebts(debtAmountData.total_debts);
      setDebts(debtAmountData.debts);
      setNegotiations(negotiationsData);
      setNegotiationProgress(negotiationProgressData);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, [query]);

  useEffect(() => {
    loadNegotiationProgress();
  }, [prevYear]);

  useEffect(() => {
    console.log('teste');
    updateQuery();
  }, [receiptTypes]);

  useEffect(() => {
    loadNegotiationYear();
  }, [selectedYear, query]);

  const addReceiptType = (receiptTypeItems: number[]) => {
    setReceiptTypes(receiptTypeItems);
  };

  return (
    <DashboardContext.Provider
      value={{
        query,
        loading,
        unauthorizedDebt,
        debts,
        totalDebts,
        prescriptionsData,
        negotiations,
        negotiationProgress,
        receiptTypes,
        addReceiptType,
        prevYear,
        setPrevYear,
        selectedYear,
        setSelectedYear,
        negotiationsPerMonth,
      }}>
        {children}
      </DashboardContext.Provider>
  );
};

export default DashboardProvider;
