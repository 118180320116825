import { t } from 'i18next';
import {
  FaCalendarAlt, FaCheckCircle, FaEdit, FaExclamationTriangle, FaHistory,
} from 'react-icons/fa';
import { FaArrowsRotate } from 'react-icons/fa6';
import { Grid } from '@mui/material';
import { useContext, useState } from 'react';
import { Inscription, SefinSituationHistoryType } from '../../../@types/activeDebt/inscription';
import { TableData } from '../../../components/TableData';
import InscriptionService from '../../../services/inscriptionService';
import { HomeContext } from '../../../context/homeContext';
import styles from './styles.module.scss';
import { SharedResponsibles } from './SharedResponsibles';
import { Link } from '../../../components/Link';
import { Modal } from '../../../components/Modal';
import Format from '../../../helpers/format';
import { Table } from '../../../components/Table';
import { StatesHistory } from '../../../components/StatesHistory';

type DataProps = {
  data: Inscription;
  refetchData: () => void;
  modalState?: boolean;
  openPhasesModal?: () => void;
};

export function Data(props: DataProps) {
  const [openSefinSituationModal, setOpenSefinSituationModal] = useState(false);
  const { addToasts } = useContext(HomeContext);
  const updateAddress = async (admInscription: string) => {
    try {
      const result = await InscriptionService.updateAddress(admInscription);
      if (result.title) {
        props.refetchData();
        addToasts({
          type: 'success',
          text: t('inscription.data.updateAddress.success'),
          widthFull: true,
        });
      }
    } catch (error) {
      addToasts({
        type: 'error',
        text: t('inscription.data.updateAddress.error'),
      });
    }
  };

  const updateSefinSituation = async (inscriptionId: number) => {
    try {
      const result = await InscriptionService.resendSefinSituation(inscriptionId);
      if (result) {
        props.refetchData();
        addToasts({
          type: 'success',
          text: t('inscription.data.sefinSituation.success'),
          widthFull: true,
        });
      }
    } catch (error) {
      addToasts({
        type: 'error',
        text: t('inscription.data.sefinSituation.error'),
      });
    }
  };

  const data = [
    {
      label: t('inscription.data.responsible'),
      value: (
        <div className={styles.actionRow}>
          <span className={styles.highlightedText}>{props.data.responsible_information}</span>
          <span className={styles.actionIcon}><FaEdit/></span>
        </div>
      ),
    },
    {
      label: t('inscription.data.legalType'),
      value: props.data.responsible_legal_type,
    },
    {
      label: t('inscription.data.receiptType'),
      value: props.data.receipt_type_description,
    },
    {
      label: t('inscription.data.address'),
      value: (
        <div className={styles.actionRow}>
          <span>{props.data.display_iptu_address}</span>
          <span
            className={styles.actionIcon}
            onClick={() => updateAddress(props.data.administrative_inscription)}
          >
            <FaArrowsRotate/>
          </span>
        </div>
      ),
    },
    {
      label: t('inscription.data.fiscalDate'),
      value: props.data.fiscal_date,
    },
    {
      label: t('inscription.data.baseDocument'),
      value: props.data.base_document_text,
    },
    {
      label: t('inscription.data.origin'),
      value: props.data.institution_name,
    },
    {
      label: t('inscription.data.inscriptionDate'),
      value: props.data.inscription_date,
    },
    {
      label: t('inscription.data.phase'),
      value: (
        <Link
          data-cy='data-cy-open-phases-history'
          onClick={props.openPhasesModal}
        >
          {props.data.history_inscription_states[0]?.to_state}
        </Link>
      ),
    },
    {
      label: t('inscription.data.paymentRequest'),
      value: (
        <Link
          href={`/payment_request/${props.data.installment_payment_request}`}
          target='_blank'
        >
          {props.data.payment_request_code}
        </Link>
      ),
    },
    {
      label: t('inscription.data.legalProvisions'),
      value: props.data.legal_provisions,
    },
    {
      label: t('inscription.data.spuNumber'),
      value: props.data.spu_number,
    },
    {
      label: t('inscription.data.documentNumber'),
      value: props.data.document_number,
    },
    {
      label: t('inscription.data.documentDate'),
      value: props.data.document_date,
    },
    {
      label: t('inscription.data.sefinSituation.title'),
      value: (
        <div className={styles.actionRow}>
          <Link
            onClick={() => setOpenSefinSituationModal(true)}
            data-cy='data-cy-sefin-situation-history-id'
          >
            {props.data.sefin_situation}
          </Link>
          <span
            className={styles.actionIcon}
            onClick={() => updateSefinSituation(props.data.id)}
          >
            <FaArrowsRotate/>
          </span>
        </div>
      ),
    },
  ];

  return (
    <Grid container spacing={2} className={styles.container}>
      <Grid item xs={12} sm={12} md={8}>
        <TableData items={data} />
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
        <SharedResponsibles data={props.data.shared_responsibles} />
      </Grid>
      <Modal
        title={t('debtBox.results.inscriptions.debts.sefinSituationHistory.title')}
        open={openSefinSituationModal}
        onClose={() => setOpenSefinSituationModal(false)}
        icon={<FaHistory/>}
        size='large'
      >
        <Table flat={true}>
          <thead data-cy='data-cy-sefin-history-table'>
            <tr>
              <th>{t('debtBox.results.inscriptions.debts.sefinSituationHistory.table.date')}</th>
              <th>{t('debtBox.results.inscriptions.debts.sefinSituationHistory.table.situation')}</th>
              <th>{t('debtBox.results.inscriptions.debts.sefinSituationHistory.table.protocol')}</th>
              <th>{t('debtBox.results.inscriptions.debts.sefinSituationHistory.table.response')}</th>
            </tr>
          </thead>
          <tbody>
            {props.data.sefin_debt_histories.map((item: SefinSituationHistoryType) => (
              <tr>
                <td className={styles.smallest}><FaCalendarAlt className={styles.calendarIcon}/>{Format.formatDateWithHours(item.created_at)}</td>
                <td className={styles.smallest}>{item.situation}</td>
                <td className={styles.smallest}>{item.receiving_protocol ? item.receiving_protocol : '-'}</td>
                <td className={styles.chekcIcon}>
                  {item.ws_response !== true
                    ? <span className={styles.responseSefin}>
                        <FaExclamationTriangle className={styles.dangerIcon}/>
                        {item.ws_response}
                      </span>
                    : <FaCheckCircle/>
                  }
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Modal>
      <StatesHistory
        data={props.data.history_inscription_states || []}
        modalState={props.modalState}
        openPhasesModal={props.openPhasesModal}
      />
    </Grid>
  );
}
