import { useQuery } from '@tanstack/react-query';
import { t } from 'i18next';
import { Table } from '../../../../components/Table';
import { Subtitle } from '../../../../components/Subtitle';
import {
  InscriptionsAdministrativeDataResumeType, AdministrativeInscriptionResumeType, InscriptionsResumeType, DebtsValuesType,
} from '../../../../@types/activeDebt/inscription';
import DebtBoxService from '../../../../services/debtBoxService';
import Format from '../../../../helpers/format';
import styles from './styles.module.scss';

type SimulationProps = {
  requestData: {};
};

export function CdasTable(props: SimulationProps) {
  const loadData = async (): Promise<InscriptionsAdministrativeDataResumeType> => {
    const result = await DebtBoxService.getResumeInscriptions(props.requestData);
    return result;
  };

  const { data, isLoading, error } = useQuery<InscriptionsAdministrativeDataResumeType>({
    queryKey: ['newContributorParams'],
    queryFn: loadData,
  });

  if (isLoading || error || (!data || !data.data || data.data.length === 0)) {
    return <div></div>;
  }

  return (
    <div>
      {data.data.map((adminInscription: AdministrativeInscriptionResumeType) => (
        <div key={adminInscription.administrative_inscription} className={styles.tableContainer}>
          <Subtitle text={`Ins. Adm: ${adminInscription.administrative_inscription}`} />
          <div className={styles.table}>
            <Table>
              <thead>
                <tr>
                  <th data-cy={'cdas-table-cda'} className={`${styles.bold} ${styles.center}`}>
                    {t('reports.inscriptions.table.cda')}
                  </th>
                  <th data-cy={'cdas-table-state'} className={`${styles.bold} ${styles.center}`}>
                    {t('reports.inscriptions.table.state')}
                  </th>
                  <th data-cy={'cdas-table-due-date'} className={`${styles.bold} ${styles.center}`}>
                    {t('reports.inscriptions.table.due_date')}
                  </th>
                  <th data-cy={'cdas-table-process-number'} className={`${styles.bold} ${styles.center}`}>
                    {t('reports.inscriptions.table.judicial_process_number')}
                  </th>
                  <th data-cy={'cdas-table-main-balance'} className={styles.bold}>
                    {t('reports.inscriptions.table.main_balance')}
                  </th>
                  <th data-cy={'cdas-table-fines-balance'} className={styles.bold}>
                    {t('reports.inscriptions.table.fines_balance')}
                  </th>
                  <th data-cy={'cdas-table-monetary-correction'} className={styles.bold}>
                    {t('reports.inscriptions.table.monetary_correction')}
                  </th>
                  <th data-cy={'cdas-table-moratorium-fines'} className={styles.bold}>
                    {t('reports.inscriptions.table.moratorium_fines')}
                  </th>
                  <th data-cy={'cdas-table-interest-value'} className={styles.bold}>
                    {t('reports.inscriptions.table.interest_value')}
                  </th>
                  <th data-cy={'cdas-table-fee-value'} className={styles.bold}>
                    {t('reports.inscriptions.table.fee_value')}
                  </th>
                  <th data-cy={'cdas-table-balance'} className={styles.bold}>
                    {t('reports.inscriptions.table.balance')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {adminInscription.inscriptions.map((inscription: InscriptionsResumeType) => inscription.debts.map((debt: DebtsValuesType) => (
                    <tr key={debt.id} className={styles.cdasTableContent}>
                      <td className={`${styles.center} ${styles.dark}`}>
                        {inscription.cda_number}
                      </td>
                      <td className={`${styles.center} ${styles.dark} ${styles.leftBorder}`}>
                        {inscription.state_i18n}
                      </td>
                      <td className={`${styles.center} ${styles.dark} ${styles.leftBorder}`}>
                        {Format.formatDateYear(debt.due_date)}
                      </td>
                      <td className={`${styles.center} ${styles.dark} ${styles.leftBorder}`}>
                        {inscription.judicial_process_number}
                      </td>
                      <td className={`${styles.dark} ${styles.leftBorder}`}>
                        {debt.main_balance && Format.currency(debt.main_balance)}
                      </td>
                      <td className={styles.dark}>
                        {debt.fines_balance && Format.currency(debt.fines_balance)}
                      </td>
                      <td className={styles.dark}>
                        {debt.monetary_correction && Format.currency(debt.monetary_correction)}
                      </td>
                      <td className={styles.dark}>
                        {debt.moratorium_fines && Format.currency(debt.moratorium_fines)}
                      </td>
                      <td className={styles.dark}>
                        {debt.interest_value && Format.currency(debt.interest_value)}
                      </td>
                      <td className={styles.dark}>
                        {debt.fee_value && Format.currency(debt.fee_value)}
                      </td>
                      <td className={`${styles.dark} ${styles.bold}`}>
                        {debt.balance && Format.currency(debt.balance)}
                      </td>
                    </tr>
                )))}
              </tbody>
            </Table>
          </div>
        </div>
      ))}
    </div>
  );
}
