import { Grid } from '@mui/material';
import { t } from 'i18next';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import styles from './styles.module.scss';
import { Breadcumb } from '../../../components/Breadcumb';
import { Title } from '../../../components/Title';
import { Tabs } from '../../../components/Tabs';
import { DebtInfoForm } from './DebtInfoForm';
import { Responsibles } from './Responsibles';
import { PropertyInfo } from './PropertyInfo';
import { Button } from '../../../components/Button';
import RequirementService from '../../../services/requirement';
import Show from '../../../components/Show';

export function NewRequirement() {
  const { pathname } = useLocation();

  const loadData = async () => {
    const result = await RequirementService.newRequeriment();
    return result;
  };

  const {
    data,
  } = useQuery({
    queryKey: ['newRequirementData'],
    queryFn: loadData,
  });

  useEffect(() => {

  }, []);

  const renderTabs = () => {
    const tabs = [
      {
        id: 1,
        title: t('requirements.new.tabs.debtInfo'),
        content: <DebtInfoForm data={data} />,
      },
      {
        id: 2,
        title: t('requirements.new.tabs.responsibles'),
        content: <Responsibles />,
      },
      {
        id: 3,
        title: t('requirements.new.tabs.propertyInfo'),
        content: <PropertyInfo />,
      },
    ];
    return <Tabs tabs={tabs} />;
  };
  return (
    <div className={ styles.container }>
      <Show if={!!data}>
        <Grid
          container
          columns={{ xs: 2, sm: 6, md: 12 }}
          spacing={1}
          className={styles.container}
        >
          <Grid item xs={2} sm={6} md={12}>
            <Title text={ t('requirements.new.title') } />
            <Breadcumb pathnames={pathname.split('/').filter((x) => x)}/>
          </Grid>

          <div className={styles.content}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={10}>
                <Title text={ t('requirements.new.subtitle') } />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                {renderTabs()}
                <div className={styles.footer}>
                  <Button
                    title={t('requirements.new.responsibles.submit')}
                    size='large'
                    round
                    buttonType='primary'
                    disabled={false}
                    type='submit'
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Show>
    </div>
  );
}
