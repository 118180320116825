import { t } from 'i18next';
import Format from '../../../../helpers/format';
import styles from './styles.module.scss';

type DebtBoxesProps = {
  totalBalance: number | string;
  totalCDAs?: number;
  openInscriptions?: { balance: number | string, total: number };
  negotiationInscriptions?: { balance: number | string, total: number }
};

export function DebtBoxes(props: DebtBoxesProps) {
  return (
    <div className={styles.debtBoxes}>
        <div className={styles.infoCards}>
          <div className={styles.content}>
            <span className={styles.cardTittle}><strong>{t('reports.fullStatement.totalBalance')}</strong> (A+B)</span>
            <div className={styles.values}>
              <span>{`${props.totalCDAs} CDAs`}</span>
              <span className={styles.mainBalance}>{props.totalBalance && Format.currency(props.totalBalance)}</span>
            </div>
          </div>
          <div className={styles.content}>
            <span className={styles.cardTittle}><strong>{t('reports.fullStatement.openDebts')}</strong> (A)</span>
            <div className={styles.values}>
              <span>{`${props.openInscriptions?.total} CDAs`}</span>
              <span className={styles.balance}>{props.openInscriptions && Format.currency(props.openInscriptions.balance)}</span>
            </div>
          </div>
          <div className={styles.content}>
            <span className={styles.cardTittle}><strong>{t('reports.fullStatement.negotiationDebts')}</strong> (B)</span>
            <div className={styles.values}>
              <span>{`${props.negotiationInscriptions?.total} CDAs`}</span>
              <span className={styles.balance}>{props.negotiationInscriptions && Format.currency(props.negotiationInscriptions.balance)}</span>
            </div>
          </div>
        </div>
    </div>
  );
}
