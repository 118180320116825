import { useEffect } from 'react';
import styles from './styles.module.scss';
import { loadTitle } from '../../helpers/changeTitle';
import { DebtDashboard } from './DebtDashboard';
import DashboardProvider from '../../context/dashboardContext';

type HomeProps = {
  title?: string;
};

export function Home(props: HomeProps) {
  useEffect(() => {
    loadTitle(props.title);
  }, []);

  return (
    <div className={ styles.content }>
      <DashboardProvider>
        <DebtDashboard />
      </DashboardProvider>
    </div>
  );
}
