import { Trans } from 'react-i18next';
import { Grid } from '@mui/material';
import { t } from 'i18next';
import { FaPlus } from 'react-icons/fa6';
import { useEffect, useState } from 'react';
import { FaPaperclip } from 'react-icons/fa';
import styles from './styles.module.scss';
import { InputText } from '../../../../components/InputText';
import { Subtitle } from '../../../../components/Subtitle';
import Select from '../../../../components/Select';
import { AttachDocument as AttachDocumentType, OptionType } from '../../../../@types/config';
import { InputDate } from '../../../../components/InputData';
import { TextArea } from '../../../../components/TextArea';
import { Link } from '../../../../components/Link';
import { RoundButton } from '../../../../components/RoundButton';
import { Modal } from '../../../../components/Modal';
import { AttachDocument } from '../../../PaymentRequest/Documents/AttachDocument';
import { NewRequirementType } from '../../../../@types/requirement';
import DebtBoxService from '../../../../services/debtBoxService';
import { AttachedDocument } from '../../../../@types/activeDebt/inscription';
import InscriptionService from '../../../../services/inscriptionService';
import { ListFiles } from './ListFiles';
import Show from '../../../../components/Show';

type DebtInfoFormProps = {
  data?: NewRequirementType;
};

export function DebtInfoForm(props: DebtInfoFormProps) {
  const [showAttach, setShowAttach] = useState(false);
  const [documentTypes, setDocumentTypes] = useState<OptionType[]>([]);
  const [files, setFiles] = useState<AttachedDocument[]>([]);

  useEffect(() => {
    InscriptionService.getInscriptionsDocumentsType()
      .then((res) => setDocumentTypes(res.result));
  }, []);

  const removeFile = (filesParam: AttachDocumentType) => {
    const newState = files.filter((el) => el.id !== filesParam.id);
    setFiles(newState);
  };

  const handleAddFiles = (newFiles: AttachedDocument[]) => {
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    setShowAttach(false);
  };

  const receiptTypes = () => {
    const taxTypes = props.data?.receipts_type_grouped_for_select.tax.map((type: any) => { return { label: `${t('requirements.new.form.tax')} - ${type.label}`, value: type.value }; });
    const nontaxTypes = props.data?.receipts_type_grouped_for_select.nontax.map((type: any) => { return { label: `${t('requirements.new.form.nontax')} - ${type.label}`, value: type.value }; });
    return [...taxTypes, ...nontaxTypes];
  };

  return (
    <div className={ styles.container }>
      <span className={styles.lawText}>
        <Trans
          i18nKey='requirements.new.lawText'
          components={{ bold: <strong /> }}
        />
      </span>
      <div className={styles.content}>
        <Subtitle text={t('requirements.new.form.title')} primaryColor />
        <Grid
          container
          columns={{ xs: 2, sm: 6, md: 12 }}
          spacing={2}
          className={styles.form}
        >
          <Grid item xs={2} sm={3} md={4}>
            <Select
              dataCy={'data-cy-origin'}
              labelBold
              label={t('requirements.new.form.origin')}
              placeholder={t('requirements.new.form.originPlaceholder')}
              options={props.data?.institutions || []}
              required
            />
          </Grid>
          <Grid item xs={2} sm={3} md={4}>
            <Select
              dataCy={'data-cy-receipt-type'}
              labelBold
              label={t('requirements.new.form.receiptType')}
              placeholder={t('requirements.new.form.receiptTypePlaceholder')}
              options={receiptTypes()}
              required
            />
          </Grid>
          <Grid item xs={2} sm={3} md={4}>
            <Select
              dataCy={'data-cy-credit-source'}
              labelBold
              label={t('requirements.new.form.creditSource')}
              placeholder={t('requirements.new.form.creditSourcePlaceholder')}
              options={props.data?.credit_sources || []}
              required
            />
          </Grid>
          <Grid item xs={2} sm={3} md={4}>
            <Select
              dataCy={'data-cy-document-base'}
              labelBold
              label={t('requirements.new.form.documentBase')}
              placeholder={t('requirements.new.form.documentBasePlaceholder')}
              options={props.data?.inscription_base_documents || []}
              required
            />
          </Grid>
          <Grid item xs={2} sm={3} md={4}>
            <InputText
              dataCy={'data-cy-document-number'}
              labelBold
              label={t('requirements.new.form.documentNumber')}
              placeholder={t('requirements.new.form.documentNumberPlaceholder')}
            />
          </Grid>
          <Grid item xs={2} sm={3} md={4}>
            <InputDate
              dataCy={'data-cy-document-date'}
              label={t('requirements.new.form.documentDate')}
              onChange={() => {}}
            />
          </Grid>
          <Grid item xs={2} sm={6} md={12}>
            <TextArea
              dataCy={'data-cy-observartion'}
              labelBold
              label={t('requirements.new.form.observation')}
              placeholder={t('requirements.new.form.observationPlaceholder')}
            />
          </Grid>
        </Grid>
        <Subtitle text={t('requirements.new.debtor.title')} primaryColor />
        <Grid
          container
          columns={{ xs: 2, sm: 6, md: 12 }}
          spacing={2}
          className={styles.form}
        >
          <Grid item xs={2} sm={3} md={4}>
            <Select
              dataCy={'data-cy-select-debtor'}
              labelBold
              label={t('requirements.new.debtor.selectDebtor')}
              placeholder={t('requirements.new.debtor.selectDebtorPlaceholder')}
              options={[]}
              urlToUpdate={DebtBoxService.getContributorList}
              required
            />
          </Grid>
          <Grid item xs={2} sm={3} md={8} className={styles.rightItem}>
            <Link href='/contributor_box' target='_blank'>
              {t('requirements.new.debtor.newDebtor')}
            </Link>
          </Grid>
        </Grid>

        <Subtitle text={t('requirements.new.attach.title')} primaryColor />
        <Grid
          container
          columns={{ xs: 2, sm: 6, md: 12 }}
          spacing={2}
          className={styles.form}
        >
          <Grid item xs={2} sm={3} md={4}>
            <RoundButton
              dataCy={'data-cy-attach-documents'}
              icon={<FaPlus />}
              backgroundColor='var(--primary4)'
              onClick={() => setShowAttach(true)}
            />
          </Grid>
          <Show if={files.length > 0}>
            <Grid item xs={2} sm={6} md={12}>
              <ListFiles
                documents={files}
                removeFile={removeFile}
                types={documentTypes}
              />
            </Grid>
          </Show>
        </Grid>
      </div>
      <Modal
        title={t('paymentRequest.documents.attachDocument.title')}
        open={showAttach}
        onClose={() => setShowAttach(false)}
        icon={<FaPaperclip />}
        size='large'
      >
        <AttachDocument
          handleFunction={(val) => handleAddFiles(val)}
          documentType={documentTypes}
        />
      </Modal>
    </div>
  );
}
