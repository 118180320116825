/* eslint-disable import/no-default-export */
import ptBrTranslations from './pt-br';
import faqTranslations from './faq';
import debitBoxTranslations from './debtBox.pt-br';
import requirementsTranslations from './requirements.pt-br';
import extinguishTranslations from './extinguish.pt-br';

export default {
  'pt-BR': {
    translations: {
      ...faqTranslations.translations,
      ...ptBrTranslations.translations,
      ...debitBoxTranslations.translations,
      ...requirementsTranslations.translations,
      ...extinguishTranslations.translations,
    },
  },
};
