export default {
  translations: {
    general: {
      concluded: 'Concluído',
      confirm: 'Confirmar',
      edit: 'Editar',
      destroy: 'Excluir',
      requeried: 'Campo obrigatório',
      noOptions: 'Sem resultados',
      emptyOptions: 'Sem opções',
      changePassword: 'Alterar senha',
      yes: 'Sim',
      no: 'Não',
      cancel: 'Cancelar',
      tryAgain: 'Tentar novamente',
      refresh: 'Atualizar',
      save: 'Salvar',
      dashboard: 'Meu dashboard',
      cpf: 'CPF inválido',
      cnpj: 'CNPJ inválido',
      cep: 'CEP inválido',
      cepError: 'Não foi possivel obter o endereço',
      date: 'A data deve ser maior ou igual à data de hoje',
      invalidDate: 'Data inválida',
      darkMode: 'Modo escuro',
      lightMode: 'Modo claro',
      message: {
        emptyList: 'Nenhum processo foi encontrado na sua caixa.',
        label: 'Aplique sua pesquisa no Acervo da PGM',

      },
      password: {
        current: 'Senha atual',
        new: 'Nova senha',
        newPlaceholder: 'Digite a nova senha',
        confirmation: 'Confirmação',
        confirmationPlaceholder: 'Confirme a nova senha',
        validation: 'Não corresponde com a nova senha',
        requeried: 'Campo obrigatório',
        success: 'Senha alterada com sucesso',
        error: 'Erro ao alterar senha. Verifique se a senha atual está correta.',
      },
      login: {
        password: 'Senha',
        passwordPlaceholder: 'Sua senha',
        username: 'Usuário',
        usernamePlaceholder: 'Seu e-mail ou CPF',
        signIn: 'Acessar',
        forgotPassword: 'Esqueceu a senha?',
        contact: 'Dúvidas? <a href="https://wa.me/558589260327?agilis=true" target="_blank">Fale conosco</a>',
        footer: 'Desenvolvido pela Procuradoria Geral do Município de Fortaleza (PGM). 2022.',
        slogan: 'Sua procuradoria digital, simples e ágil',
        emptyFields: 'Digite um usuário e senha',
        forgotPasswordModal: {
          emailInstructions: 'Você receberá um e-mail com instruções para troca de senha.',
          email: 'Digite seu e-mail',
          emailPlaceholder: 'Seu e-mail ou usuário',
          submit: 'Enviar',
          error: 'Falha ao buscar o usuário',
        },
        recoverPassword: {
          title: 'Troque sua senha',
          newPassword: 'Nova senha',
          newPasswordPlaceholder: 'Digite sua nova senha',
          confirmPassword: 'Confirmar senha',
          submit: 'Salvar nova senha',
          differentPassword: 'As senhas devem ser iguais',
          emailSend: 'Aguarde, em instantes você irá receber o e-mail para troca de senha.',
          error: 'Algo deu errado',
          success: 'Senha alterada com sucesso, <a href="/">clique aqui</a> para fazer o login',
        },
      },
      toast: {
        warning: 'Aviso',
        info: 'Info',
        success: 'Sucesso',
        error: 'Erro',
      },
      logout: {
        label: 'Sair do Ágilis',
        text: 'Deseja realmente sair do Ágilis',
      },
      changePicture: {
        title: 'Alterar foto do perfil',
        text: 'Uma foto no seu perfil ajuda a outros colaboradores a reconhecerem você.',
        add: 'Adicionar foto de perfil',
        change: 'Mudar foto de perfil',
        remove: 'Remover foto de perfil',
        deleteError: 'Erro ao remover foto de perfil. Tente novamente mais tarde.',
        deleteSuccess: 'Foto de perfil removida com sucesso',
        changeError: 'Erro ao alterar foto de perfil. Tente novamente mais tarde.',
        changeSuccess: 'Foto de perfil alterada com sucesso',
      },
      modalOutSideConfirmationAlert: {
        title: 'Deseja realmente sair?',
        text: 'As ações realizadas neste andamento serão perdidas',
      },
    },
    topbar: {
      rightItems: {
        user_name: 'Nome do Usuário',
      },
    },
    sidebar: {
      modules: {
        title: 'Módulos',
        systems: 'Outros sistemas',
        active_debt: {
          title: 'Dívida ativa',
          home: 'Homescreen',
          requeriment: {
            title: 'Requerimento',
            inscribe: 'Inscrever requerimento',
            request_analysis: 'Solicitar análise',
            register: 'Cadastrar requerimento',
          },
          inscription: {
            title: 'Inscrições',
            consult_advanced_debit: 'Consultar avançada de débito',
            extract_debit: 'Extrato de débitos',
            statement_responsibility: 'Emitir declaração de responsabilidade',
            extinguish_suspend_credit: 'Extinguir/suspender crédito',
            ownership_change: 'Alteração de titularidade',
            suspension_extinction_requests: 'Solicitações de suspensão/extinção',
            migration_registered_debts: 'Migração de dívidas inscritas',
            approve_migration_registered_debts: 'Aprovar migração de dívidas inscritas',
            retroactive_calculation: 'Cálculo retroativo',
          },
          protest: {
            title: 'Protesto',
            request_protest: 'Solicitar protesto',
            abandonment_cancellation: 'Desistência/cancelamento',
          },
          judgment: {
            title: 'Ajuizamento',
            request: 'Solicitar ajuizamento',
            forward: 'Encaminhar ajuizamento',
          },
          management: {
            title: 'Gestão',
            active_debt_diagnosis: 'Diagnóstico da dívida ativa',
            active_debt_diagnosis_phase: 'Diagnóstico da dívida ativa por fase',
            active_debt_diagnosis_range: 'Diagnóstico da dívida ativa por faixa de valor',
            geolocation_contributors: 'Geolocalização dos contribuintes',
            geolocation_contributors_regions: 'Geolocalização dos contribuintes por bairros/regionais',
            overview_municipal_active_debt: 'Visão geral da dívida ativa municipal',
            overview_prodat: 'Visão geral da dívida ativa (PRODAT)',
            overview_installment_management: 'Visão gerencial de parcelamentos',
            collection_management: 'Gestão da arrecadação',
            overview_judgment: 'Visão gerencial do ajuizamento',
            overview_protest: 'Visão gerencial do protesto',
            evolution_debt_stock: 'Evolução do estoque da dívida',
            overview_phases_debt_inscriptions: 'Visão das fases das inscrições dos débitos',
          },
          reports: {
            title: 'Relatórios',
            extract_inscriptions: 'Extrato de inscrições',
            general_collection: 'Arrecadação geral',
            tax_collection: 'Arrecadação por tributo',
            collection_charges: 'Arrecadação de encargos',
            management_view_active_debt: 'Visão gerencial da dívida ativa',
            manage_installments_agreements: 'Gerenciar parcelamentos/acordos',
            report_requests_API: 'Relatório das solicitações de requerimento via API',
          },
          settings: {
            title: 'Configurações',
            inform_tax: 'Informar taxas',
            calculation_standards: 'Padrões de cálculo',
            blocked_inscriptions: 'Inscrições bloqueadas',
            refis: 'REFIS',
            scheduling: 'Agendamento',
          },
          debt_box: 'Caixa de dívidas',
          financial: {
            title: 'Financeiro',
          },
          contributor_box: {
            contributor: 'Contribuinte',
            contributorArea: 'Área do contribuinte',
            createEditContributor: 'Cadastrar/editar contribuinte',
          },
        },
        support: {
          title: 'Suporte',
          suggestion_box: 'Caixa de sugestões',
          contact_us: 'Fale conosco',
          faq: 'FAQ (Dúvidas Frequentes)',
          settings_sistem: 'Configurações do sistema',
        },
        settings: {
          user_management: 'Gestão de usuários',
        },
      },
    },
    home: {
      dashboard: {
        title: 'Dashboard',
        lastUpdated: 'Última atualização: {{date}}',
        lock: 'Por que estou bloqueado?',
        search: {
          placeholder: 'Pesquise...',
        },
        receiptTypeFilter: {
          placeholder: 'Todos os tributos',
        },
        areasFilter: {
          placeholder: 'Todas as áreas da PGM',
        },
        debt: {
          title: 'Dívida ativa',
          tooltip: 'Os gráficos mostram os valores totais do estoque da dívida ativa e a análise do desempenho por tipo de tributo.',
          goToBox: 'Acessar caixa de créditos',
          unauthorized: 'Dashboard de Dívida Ativa está bloqueado para seu tipo de acesso.',
        },
        digitalProcesses: {
          title: 'Processos digitais',
          tooltip: 'Os gráficos mostram a quantidade de processos por período e a análise do desempenho a partir da quantidade de entradas e saídas de processos.',
          goToBox: 'Acessar caixa de processos',
          unauthorized: 'Dashboard de Processos Digitais está bloqueado para seu tipo de acesso.',
          totals: {
            inbox: {
              title: 'Caixa de entrada',
              tooltip: 'Nº total de processos na minha caixa subtraído pelo Nº de saídas.',
            },
            outbox: {
              title: 'Saída',
              tooltip: 'Nº de processos que saíram da caixa de entrada hoje.',
            },
            inputbox: {
              title: 'Novos',
              tooltip: 'Nº de processos que chegaram da sua caixa de entrada hoje.',
            },
            title: 'Total de processos',
            tooltip: 'Os dados abaixo são referentes ao mês vigente do ano corrente.',
          },
          exportSpreadsheet: {
            title: 'Selecione o período',
            message: 'A planilha será gerada de acordo com o período da sua escolha.',
            toast: 'Planilha de andamento(s) "{{type}}" gerada com sucesso.',
            button: 'Exportar planilha',
            alert: {
              title: '...Gerando planilha de andamento',
              message: 'Aguarde até o download ser iniciado.',
            },
          },
        },
        actions: {
          export: 'Exportar',
        },
      },
    },
    dashboard: {
      debtAmount: {
        title: 'Acervo da dívida ativa',
        info: 'O valor total do acervo é composto pela soma dos saldos devedores de todas as CDAs que estão ativas até a data atual.',
        tooltip: 'Os dados abaixo são referentes ao ano corrente.',
      },
      progressNegotiation: {
        title: 'Andamento dos créditos',
        tooltip: 'Distribuição do estoque de dívida ativa de acordo com cada fase da CDA.',
        start: 'Números de',
        end: 'em comparativo com o mesmo período de',
        period: 'ano do período:',
      },
      negotiations: {
        header: 'Arrecadação anual da Dívida Ativa no ano de',
        title: 'Negociação da dívida',
        total: 'Total de negociações',
        tooltip: 'Negociações iniciadas no ano corrente e análise de desempenho das negociações ativas.',
        paid: 'Total arrecadado no ano selecionado',
        lost: 'Negociações perdidas do ano corrente',
        active: 'Parcelamentos ativos (Promessas)',
        overdue: 'Negociações em atraso (Promessas)',
        totalInfo: 'O valor apresentado é a soma de todas as negociações ativas, independente de estarem atrasadas ou não.',
        activeInfo: 'O valor apresentado é a soma de todas as negociações ativas que não estão atrasadas.',
        overdueInfo: 'O valor apresentado é a soma de todas as negociações ativas que estão atrasadas.',
        lostInfo: 'O valor apresentado é a soma de todas as negociações perdidas/canceladas, durante o ano corrente.',
        period: 'Os dados abaixo são referentes ao ano corrente.',
      },
      prescriptions: {
        title: 'Prescrições',
        tooltip: 'Créditos extintos por ultrapassar o tempo de pagamento e atingir valor superior à R$ 6.000,00.',
        toPrescribe: 'A prescrever',
        toPrescribeTooltip: 'Previsão do valor total de créditos a prescrever no mês.',
        wouldPrescribe: 'Iria prescrever',
        prescribedUntil: 'Prescrito até ',
        prescribed: 'Prescrito',
        prescribedTooltip: 'Total de créditos que foram prescritos no mês.',
        period: 'A prescrever (entre hoje até o fim de {{current_year}}): ',
        current: 'Referente ao ano atual',
        tooltipToPrescribe: `A prescrever: o valor mensal ${'a prescrever'} é atualizado até o dia primeiro do mês correspondente.`,
        tooltipPrescribed: 'Prescritos: no mês corrente o valor de créditos prescritos são atualizados diariamente.',
        months: {
          1: 'Jan',
          2: 'Fev',
          3: 'Mar',
          4: 'Abr',
          5: 'Mai',
          6: 'Jun',
          7: 'Jul',
          8: 'Ago',
          9: 'Set',
          10: 'Out',
          11: 'Nov',
          12: 'Dez',
        },
      },
      digitalProcesses: {
        seeArea: 'Ver área ({{length}} colaborador{{plural}})',
        totals: {
          inbox: 'Caixa de entrada',
          outbox: 'Saída',
          inputbox: 'Novos',
          title: 'Total de processos',
          tooltip: 'Os dados abaixo são referentes ao mês vigente do ano corrente.',
        },
        processLegend: {
          unread: {
            title: 'Processos não lidos',
            description: 'Total de processos que não foram visualizados ainda pelo(s) responsável (is) atual (is).',
          },
          exit: {
            title: 'Saída de processos',
            description: 'Total de processos que foram arquivados',
            descriptionField: 'Total de processos que saíram da área por meio de tramitação ou arquivamento',
            descriptionIndividual: 'Total de processos que saíram da caixa de entrada do responsável atual, por tramitação ou arquivamento.',
          },
          new: {
            title: 'Novos processos',
            description: 'Total de processos que foram criados/cadastrados por todas as áreas',
            descriptionField: 'Total de processos que foram criados ou que entraram na área',
            descriptionIndividual: 'Total de processos que foram criados pelo responsável atual (que não saíram de sua caixa) e que entraram na sua caixa de entrada por meio de tramitação ou distribuição',
          },
          total: {
            title: 'Total de processos',
            description: 'Total de processos que estão ativos na PGM',
            descriptionField: 'Total de processos que estão ativos e com usuários da área',
            descriptionIndividual: 'Total de processos que estão na caixa de entrada do responsável atual',
          },
        },
        chart: {
          new: 'novos: {{value}}',
          not_read: 'não lidos: {{value}}',
          exit: 'saída: {{value}}',
        },
        performance: {
          title: 'Gráfico de desempenho',
          tooltip: 'Quantidade mensal de saídas, entradas e o total de processos, de forma progressiva.',
          total_inbox: 'total',
          total_outbox: 'saída',
          total_inputbox: 'novos',
          total_snapshot: 'estoque',
        },
        steps: {
          title: 'Gráfico de andamentos',
          tooltip: 'Quantidade de andamentos realizados nos processos.',
          total: 'Total',
        },
        productivityChart: {
          title: 'Colaboradores',
          tooltip: 'Quantidade mensal de saídas, entradas e o total de processos por cada colaborador.',
          dateTooltip: 'Os dados abaixo são referentes ao mês vigente do ano corrente',
          totalInbox: 'total:',
          totalInputbox: 'novos:',
          totalOutbox: 'saída:',
          placeholder: 'Busca por colaborador...',
          placeholderProcess: 'Buscar por processos',
        },
        searchField: {
          placeholder: 'Selecione uma área ou colaborador',
        },
      },
      month: {
        jan: 'Janeiro',
        feb: 'Fevereiro',
        mar: 'Março',
        apr: 'Abril',
        may: 'Maio',
        jun: 'Junho',
        jul: 'Julho',
        aug: 'Agosto',
        sep: 'Setembro',
        oct: 'Outubro',
        nov: 'Novembro',
        dec: 'Dezembro',
      },
      state: {
        total_requirement_requested: 'Requerimento solicitado',
        total_requirement_approved: 'Requerimento aprovado',
        total_inscript: 'Inscrito em dívida',
        sent_protest: 'Protestado',
        legal_action: 'Ajuizado',
        canceled: 'Cancelado',
        prescribed: 'Prescrito',
        settled: 'Quitado',
        redeemed: 'Remido',
      },
      general: {
        untilToday: 'até hoje',
      },
    },
  },
};
