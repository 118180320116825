const routesList: { [key: string]: string } = {
  debt_box: 'Carteira de créditos',
  debt_box_search: 'Resultado da busca',
  payment_request: 'Detalhes da negociação',
  inscription: 'Certidão de Dívida Ativa',
  contributor_box: 'Área do contribuinte',
  faq: 'Faq',
  new_requirement: 'Cadastrar requerimento',
  extinguish: 'Extinguir/suspender crédito',
};

export function translateRoute(value: string): string {
  return routesList[value];
}

export function isValidRoute(value: string): boolean {
  return routesList[value] !== undefined;
}
