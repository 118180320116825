import { Grid } from '@mui/material';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import Select from '../../../components/Select';
import styles from './styles.module.scss';
import DebtBoxService from '../../../services/debtBoxService';
import { OptionType } from '../../../@types/config';
import { InputText } from '../../../components/InputText';
import MultipleSelect from '../../../components/MultipleSelect';
import { Button } from '../../../components/Button';

export function SearchInfo() {
  const [contributor, setContributor] = useState<OptionType | undefined>();
  const [processNumber, setProcessNumber] = useState<any>();
  const [inscription, setInscription] = useState<OptionType[]>([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  useEffect(() => {
    if (contributor || processNumber || inscription.length > 0) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [contributor, processNumber, inscription]);

  return (
    <div className={ styles.container }>
      <Grid
        container
        columns={{ xs: 2, sm: 6, md: 12 }}
        spacing={2}
      >
        <Grid item xs={2} sm={3} md={4}>
          <Select
            dataCy='data-cy-contributor-select'
            label={t('extinguish.contributor')}
            placeholder={t('extinguish.contributorLabel')}
            options={[]}
            value={contributor}
            urlToUpdate={DebtBoxService.getContributorList}
            onChange={(val) => setContributor(val || undefined)}
            labelBold
          />
        </Grid>
        <Grid item xs={2} sm={3} md={4}>
          <MultipleSelect
            dataCy='data-cy-administrative-inscriptions-select'
            label={t('extinguish.admInscription')}
            placeholder={t('extinguish.admInscriptionLabel')}
            selectedOptions={inscription || []}
            options={[]}
            urlToUpdate={DebtBoxService.getInscriptionsByNumberList}
            onChange={(val) => setInscription(val || [])}
            labelBold
          />
        </Grid>
        <Grid item xs={2} sm={3} md={4}>
          <InputText
            dataCy='data-cy-judicial-process'
            label={t('extinguish.process')}
            placeholder={t('extinguish.processLabel')}
            value={processNumber}
            onChange={(val) => setProcessNumber(val.target.value)}
            labelBold
          />
        </Grid>
        <Grid item xs={2} sm={6} md={12} className={styles.footer}>
          <Button
            dataCy='data-cy-submit-search-form'
            title={t('extinguish.search')}
            size='large'
            round
            buttonType='primary'
            disabled={isButtonDisabled}
            type='submit'
          />
        </Grid>
      </Grid>
    </div>
  );
}
