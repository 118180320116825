import { Trans } from 'react-i18next';
import { t } from 'i18next';
import { Grid } from '@mui/material';
import { FaPlus, FaTrashAlt } from 'react-icons/fa';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { Subtitle } from '../../../../components/Subtitle';
import styles from './styles.module.scss';
import Select from '../../../../components/Select';
import { Link } from '../../../../components/Link';
import { RoundButton } from '../../../../components/RoundButton';
import DebtBoxService from '../../../../services/debtBoxService';

export function Responsibles() {
  const { control, handleSubmit } = useForm({
    defaultValues: {
      responsibles: [{ value: '' }],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'responsibles',
  });

  const onSubmit = (data: any) => {};

  return (
    <div className={styles.container}>
      <span className={styles.lawText}>
        <Trans
          i18nKey='requirements.new.lawText'
          components={{ bold: <strong /> }}
        />
      </span>
      <div className={styles.content}>
        <Subtitle text={t('requirements.new.responsibles.title')} primaryColor />
        <div className={styles.rightItem}>
          <Link href='/contributor_box' target='_blank'>
            {t('requirements.new.responsibles.add')}
          </Link>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            columns={{ xs: 2, sm: 4, md: 12 }}
            spacing={2}
            className={styles.form}
          >
            {fields.map((item, index) => (
              <Grid container item xs={12} spacing={2} alignItems="flex-end" key={item.id}>
                <Grid item xs={11} sm={3} md={3}>
                  <Controller
                    control={control}
                    name={`responsibles.${index}.value`}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        dataCy={'data-cy-responsibles'}
                        label={t('requirements.new.responsibles.label')}
                        placeholder={t('requirements.new.responsibles.placeholder')}
                        options={[]}
                        urlToUpdate={DebtBoxService.getContributorList}
                        labelBold
                        disableClearable
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={1} sm={1} md={1}>
                  <FaTrashAlt
                    className={styles.trashIcon}
                    onClick={() => remove(index)}
                  />
                </Grid>
              </Grid>
            ))}

            <Grid item xs={12}>
              <RoundButton
                dataCy={'data-cy-add-more-responsibles'}
                icon={<FaPlus />}
                backgroundColor='var(--primary4)'
                onClick={() => append({ value: '' })}
              />
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  );
}
