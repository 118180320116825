import { TextareaHTMLAttributes } from 'react';
import { FaRegQuestionCircle } from 'react-icons/fa';
import { Label } from '../Label';
import { Tooltip } from '../Tooltip';
import styles from './styles.module.scss';

interface InputTextProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  dataCy?: string;
  label?: string;
  labelBold?: boolean;
  tooltip?: string;
  classNameContainer?: string;
}

export function TextArea(props: InputTextProps) {
  return (
    <div className={ `${styles.container} ${props.classNameContainer}` }>
      <div className={ `${styles.header}` }>
        <Label text={props.label || ''} required={props.required} bold={!!props.labelBold} />
        {props.tooltip
          ? <Tooltip title={ props.tooltip }><FaRegQuestionCircle /></Tooltip>
          : null
        }
      </div>
        <textarea
          data-cy={props.dataCy}
          {...props}
          className={`${styles.input} ${styles.textArea} ${props.className}`}
        />
    </div>
  );
}
