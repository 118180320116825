import { Grid } from '@mui/material';
import { t } from 'i18next';
import { useState } from 'react';
import { Title } from '../../../components/Title';
import { CheckBox } from '../../../components/CheckBox';
import { Link } from '../../../components/Link';
import { Item } from '../Item';
import { RadioGroup } from '../../../components/RadioGroup';
import { OptionType } from '../../../@types/config';
import styles from './styles.module.scss';
import Show from '../../../components/Show';
import { TextArea } from '../../../components/TextArea';

export type InscriptionExtinguish = {
  id: number;
  admInscription: string;
  year: string;
  cdaNumber: string;
  details: {
    responsible: string;
    admInscription: string;
    originKey: string;
    cdaNumber: string;
    judicialProcess: string;
    state: string;
    year: string;
  }
};

const list: InscriptionExtinguish[] = [
  {
    id: 2068460,
    admInscription: '470970',
    year: '2023',
    cdaNumber: '03.0101.05.2022.00019937',
    details: {
      responsible: 'CETRAL CEARA TRANSPORTES LTDA',
      admInscription: '470970',
      originKey: 'G7044502',
      cdaNumber: '03010105202200044465',
      judicialProcess: '',
      state: 'QUITADO',
      year: '2023',
    },
  },
  {
    id: 2105873,
    admInscription: '470970',
    year: '2023',
    cdaNumber: '03.0101.05.2022.00019936',
    details: {
      responsible: 'CETRAL CEARA TRANSPORTES LTDA',
      admInscription: '470970',
      originKey: 'G7044502',
      cdaNumber: '03010105202200044465',
      judicialProcess: '',
      state: 'QUITADO',
      year: '2023',
    },
  },
  {
    id: 2105891,
    admInscription: '470970',
    year: '2023',
    cdaNumber: '03.0101.05.2022.00019935',
    details: {
      responsible: 'CETRAL CEARA TRANSPORTES LTDA',
      admInscription: '470970',
      originKey: 'G7044502',
      cdaNumber: '03010105202200044465',
      judicialProcess: '',
      state: 'QUITADO',
      year: '2023',
    },
  },
];

const options: OptionType[] = [
  {
    value: 1,
    label: 'Cancelar',
  },
  {
    value: 2,
    label: 'Suspender',
  },
  {
    value: 3,
    label: 'Remir',
  },
  {
    value: 4,
    label: 'Prescrever',
  },
];

export function Result() {
  const [selectedItems, setSelectedItems] = useState<InscriptionExtinguish[]>([]);

  const handleSelect = (item: InscriptionExtinguish) => {
    if (selectedItems.includes(item)) {
      const updatedSelection = selectedItems.filter((i) => i.id !== item.id);
      setSelectedItems(updatedSelection);
    } else {
      setSelectedItems([...selectedItems, item]);
    }
  };

  const handleSelectAll = () => {
    if (list) {
      const allSelected = list.every((item) => selectedItems.some((selected) => selected.id === item.id));
      if (allSelected) {
        setSelectedItems([]);
      } else {
        const newSelectedItems = list.filter((item) => !selectedItems.some((selected) => selected.id === item.id));
        setSelectedItems([...selectedItems, ...newSelectedItems]);
      }
    }
  };

  const checkSelection = () => {
    if (selectedItems.length === 0) {
      return 'none';
    }
    if (selectedItems.length === list.length) {
      return 'all';
    }
    if (selectedItems.length > 0 && selectedItems.length < list.length) {
      return 'partial';
    }
    return '';
  };

  return (
    <div className={ styles.container }>
      <Grid
        container
        columns={{ xs: 2, sm: 6, md: 12 }}
        spacing={2}
      >
        <Grid item xs={2} sm={6} md={12}>
          <Title text={ t('extinguish.result') } />
        </Grid>
        <Grid item xs={2} sm={6} md={12}>
          <div className={ styles.result }>
            <div className={styles.selectActions}>
              <Link onClick={() => handleSelectAll()} >
                <CheckBox
                  partially={checkSelection() === 'partial'}
                  value={['partial', 'all'].includes(checkSelection())}
                  color='var(--primary4)'
                />
                {t('extinguish.select')}
              </Link>
              <Show if={selectedItems.length > 0}>
                <Link onClick={() => setSelectedItems([])}>
                  {t('extinguish.remove')}
                </Link>
              </Show>
            </div>
            <div className={ styles.debtList }>
              {list.map((inscription: InscriptionExtinguish) => (
                <Item
                  inscription={inscription}
                  selectedItems={selectedItems}
                  onSelect={(item: InscriptionExtinguish) => handleSelect(item)}
                />
              ))}
            </div>
          </div>
        </Grid>
        <Grid item xs={2} sm={6} md={12} className={ styles.action }>
          <RadioGroup
            notDefaultOptions
            label={t('extinguish.action')}
            options={options || []}
            onChange={() => {}}
          />
        </Grid>
        <Grid item xs={2} sm={6} md={12} className={ styles.action }>
          <TextArea
            labelBold
            required
            label={t('extinguish.reason')}
            placeholder={t('extinguish.reasonPlaceholder')}
          />
        </Grid>
      </Grid>
    </div>
  );
}
