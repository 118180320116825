import {
  FaBars,
  FaTimes,
} from 'react-icons/fa';
import styles from './styles.module.scss';

type BarButtonProps = {
  opened?: boolean;
  onClick: () => void;
  mobile?: boolean;
};

export function BarButton(props: BarButtonProps) {
  return (
    <button
        data-cy={'sidebar-button'}
        className={ `${styles.barButton} ${props.mobile ? styles.barButtonMobile : ''}` }
        onClick={ props.onClick }
      >
      {props.opened ? <FaTimes className={ styles.barsIcon }/> : <FaBars className={ styles.barsIcon }/> }
    </button>
  );
}
